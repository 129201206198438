import logo from './logo.svg';
// import './App.css';
import { BrowserRouter, Route, Routes, Link } from "react-router-dom";

import "./css/style.css";
import "./css/responsive.css";
import "./css/custom-animation.css";
import "./css/framework.css";
import "./css/navbar.css";

// import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Home from "./pages/Home";
import NavBarScreen from './pages/NavBarScreen';
import Services from './pages/Services';
import USP from './pages/USP';
import Highlights from './pages/Highlights';
import Testimonials from './pages/Testimonials';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import Footer from './pages/Footer';
import PrivacyPolicy from './pages/PrivacyPolicy';

import VirtualStudio from './pages/VirtualStudio';
import ICTLab from './pages/ICTLab';
import Smartclass from './pages/Smartclass';
import Electronics from './pages/Electronics';
import SecurityForHardware from './pages/SecurityForHardware';
import ServerAndDB from './pages/ServerAndDB';
import Masterclass from './pages/Masterclass';
import DegreeLearning from './pages/DegreeLearning';
import TaskForce from './pages/TaskForce';
import Maintenance from './pages/Maintenance';
import Software from './pages/Software';

import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import { useEffect } from 'react';

// const theme = createMuiTheme({
//   breakpoints: {
//     values: {
//       xs: 0,
//       sm: 640,
//       md: 768,
//       lg: 1024,
//       xl: 1280,
//       "2xl": 1440
//     }
//   }
// });

function App() {

  useEffect(() => {
    var pgurl = window.location.href.substr(window.location.href.lastIndexOf("/") + 1);
    $(".navbar-nav > li  a").each(function () {
      if ($(this).attr("href") == pgurl || $(this).attr("href") == '')
        $(this).addClass("active");
      $(this).parent("li").addClass("active");
    })
    var timer = $('.counter');
    if (timer.length) {
      $('.counter').counterUp({
        delay: 10,
        time: 1200,
      });
    }
    $(window).on('scroll', function () {
      var sticky = $('.sticky-menu'),
        scroll = $(window).scrollTop();
      if (scroll >= 100) sticky.addClass('fixed');
      else sticky.removeClass('fixed');

    });
    $(window).on('scroll', function () {
      if ($(this).scrollTop() > 200) {
        $('.scroll-top').fadeIn();
      } else {
        $('.scroll-top').fadeOut();
      }
    });
  }, [])
  //   // ----------------------------- Lazy Load
  if ($(".lazy-img").length) {
    $('.lazy-img').Lazy({
      effect: 'fadeIn',
      threshold: 300
    });

  }


  //   // ----------------------------- Counter Function


  //   // ------------------------ Navigation Scroll


  //   // -------------------- From Bottom to Top Button


  //   //---------------------- Click event to scroll to top
  //   $('.scroll-top').on('click', function () {
  //     $('html, body').animate({ scrollTop: 0 });
  //     return false;
  //   });


  //   // });
  // }, []);


  return (
    <div>

      <BrowserRouter>
        {/* <NavBarScreen /> */}
        <Routes>
          <Route index element={<NavBarScreen />} />
          <Route exact path="/home" element={<NavBarScreen />} />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/usp" element={<USP />} />
          <Route exact path="/highlights" element={<Highlights />} />
          <Route exact path="/testimonials" element={<Testimonials />} />
          <Route exact path="/aboutus" element={<AboutUs />} />
          <Route exact path="/contactus" element={<ContactUs />} />
          <Route exact path='/footer' element={<Footer />} />
          <Route exact path="/privacy" element={<PrivacyPolicy />} />

          <Route exact path="/virtualstudio" element={<VirtualStudio />} />
          <Route exact path="/ictlab" element={<ICTLab />} />
          <Route exact path="/smartclass" element={<Smartclass />} />
          <Route exact path="/electronics" element={<Electronics />} />
          <Route exact path="/securityforhardware" element={<SecurityForHardware />} />
          <Route exact path="/serveranddb" element={<ServerAndDB />} />
          <Route exact path="/masterclass" element={<Masterclass />} />
          <Route exact path="/degreelearning" element={<DegreeLearning />} />
          <Route exact path="/taskforce" element={<TaskForce />} />
          <Route exact path="/maintenance" element={<Maintenance />} />
          <Route exact path="/software" element={<Software />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
