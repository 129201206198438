import React, { useState, useEffect } from "react";
import Footer from "./Footer.jsx"
import $ from 'jquery';
import "jquery-ui-dist/jquery-ui"
import { HiBriefcase, HiPresentationChartLine, HiCurrencyRupee, HiClipboardDocumentCheck, HiBookOpen, HiChatBubbleLeftRight } from "react-icons/hi2";

function TaskForce() {
    useEffect(() => {
        (function ($) {
            "use strict";


            // --------------------- Add .active class to current navigation based on URL
            var pgurl = window.location.href.substr(window.location.href.lastIndexOf("/") + 1);
            $(".navbar-nav > li  a").each(function () {
                if ($(this).attr("href") == pgurl || $(this).attr("href") == '')
                    $(this).addClass("active");
                // $(this).parent("li").addClass("active");
            })

            // ----------------------------- Lazy Load
            // if ($(".lazy-img").length) {
            //     $('.lazy-img').azy({
            //         effect: 'fadeIn',
            //         threshold: 300
            //     });
            // }


            // ----------------------------- Counter Function
            var timer = $('.counter');
            if (timer.length) {
                $('.counter').counterUp({
                    delay: 10,
                    time: 1200,
                });
            }

            // ------------------------ Navigation Scroll
            $(window).on('scroll', function () {
                var sticky = $('.sticky-menu'),
                    scroll = $(window).scrollTop();
                if (scroll >= 100) sticky.addClass('fixed');
                else sticky.removeClass('fixed');

            });

            // -------------------- From Bottom to Top Button
            $(window).on('scroll', function () {
                if ($(this).scrollTop() > 200) {
                    $('.scroll-top').fadeIn();
                } else {
                    $('.scroll-top').fadeOut();
                }
            });

            //---------------------- Click event to scroll to top
            $('.scroll-top').on('click', function () {
                $('html, body').animate({ scrollTop: 0 });
                return false;
            });

            // -------------------- Remove Placeholder When Focus Or Click
            $("input,textarea").each(function () {
                $(this).data('holder', $(this).attr('placeholder'));
                $(this).on('focusin', function () {
                    $(this).attr('placeholder', '');
                });
                $(this).on('focusout', function () {
                    $(this).attr('placeholder', $(this).data('holder'));
                });
            });


            // ----------------------------- Select Function
            if ($(".nice-select").length) {
                $('.nice-select').niceSelect();
            }

            // ------------------------ Feedback Slider One
            if ($(".feedback_slider_one").length) {
                $('.feedback_slider_one').slick({
                    dots: false,
                    arrows: true,
                    prevArrow: $('.prev_f1'),
                    nextArrow: $('.next_f1'),
                    centerPadding: '0px',
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    autoplay: true,
                    centerMode: true,
                    autoplaySpeed: 3000,
                    responsive: [
                        {
                            breakpoint: 992,
                            settings: {
                                slidesToShow: 1
                            }
                        }
                    ]
                });
            }


            // ------------------------ Feedback Slider Two
            if ($(".feedback_slider_two").length) {
                $('.feedback_slider_two').slick({
                    dots: false,
                    arrows: true,
                    prevArrow: $('.prev_f2'),
                    nextArrow: $('.next_f2'),
                    centerPadding: '0px',
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: true,
                    autoplaySpeed: 3000,
                });
            }

            // ------------------------ Feedback Slider Three
            if ($(".feedback_slider_three").length) {
                $('.feedback_slider_three').slick({
                    dots: true,
                    arrows: false,
                    centerPadding: '0px',
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    autoplay: true,
                    autoplaySpeed: 3000,
                    responsive: [
                        {
                            breakpoint: 1200,
                            settings: {
                                slidesToShow: 3
                            }
                        },
                        {
                            breakpoint: 992,
                            settings: {
                                slidesToShow: 2
                            }
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1
                            }
                        }
                    ]
                });
            }




            // ------------------------ Feedback Slider Four
            if ($(".feedback_slider_four").length) {
                $('.feedback_slider_four').slick({
                    centerPadding: '0px',
                    arrows: false,
                    dots: true,
                    slidesToShow: 3,
                    autoplay: true,
                    autoplaySpeed: 3000,
                    responsive: [
                        {
                            breakpoint: 1000,
                            settings: {
                                slidesToShow: 2
                            }
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1
                            }
                        }
                    ]
                });
            }


            // ------------------------ Feedback Slider Five
            if ($(".feedback_slider_five").length) {
                $('.feedback_slider_five').slick({
                    centerPadding: '0px',
                    arrows: true,
                    prevArrow: $('.prev_f2'),
                    nextArrow: $('.next_f2'),
                    dots: false,
                    slidesToShow: 4,
                    autoplay: true,
                    autoplaySpeed: 3000,
                    responsive: [
                        {
                            breakpoint: 992,
                            settings: {
                                slidesToShow: 3
                            }
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1
                            }
                        }
                    ]
                });
            }

            // ------------------------ Feedback Slider Six
            if ($(".feedback_slider_six").length) {
                $('.feedback_slider_six').slick({
                    dots: false,
                    arrows: true,
                    prevArrow: $('.prev_s2'),
                    nextArrow: $('.next_s2'),
                    centerPadding: '0px',
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: true,
                    autoplaySpeed: 3000,
                });
            }



            // ------------------------ Feedback Slider Seven
            if ($(".feedback_slider_seven").length) {
                $('.feedback_slider_seven').slick({
                    centerPadding: '0px',
                    arrows: false,
                    dots: true,
                    slidesToShow: 3,
                    centerMode: true,
                    autoplay: true,
                    autoplaySpeed: 3000,
                    responsive: [
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1
                            }
                        }
                    ]
                });
            }

            // ------------------------ Feedback Slider Nine
            if ($(".feedback_slider_nine").length) {
                $('.feedback_slider_nine').slick({
                    centerPadding: '0px',
                    arrows: false,
                    dots: true,
                    slidesToShow: 2,
                    autoplay: true,
                    autoplaySpeed: 3000,
                    responsive: [
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1
                            }
                        }
                    ]
                });
            }

            // ------------------------ Feedback Slider Ten
            if ($(".feedback_slider_ten").length) {
                $('.feedback_slider_ten').slick({
                    centerPadding: '0px',
                    arrows: true,
                    prevArrow: $('.prev_f5'),
                    nextArrow: $('.next_f5'),
                    dots: false,
                    slidesToShow: 3,
                    autoplay: true,
                    autoplaySpeed: 3500,
                    responsive: [
                        {
                            breakpoint: 1200,
                            settings: {
                                slidesToShow: 2
                            }
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1
                            }
                        }
                    ]
                });
            }

            // ------------------------ Feedback Slider Eleven
            if ($(".feedback_slider_eleven").length) {
                $('.feedback_slider_eleven').slick({
                    centerPadding: '0px',
                    arrows: false,
                    dots: true,
                    slidesToShow: 1,
                    autoplay: true,
                    autoplaySpeed: 3500,
                });
            }

            // ------------------------ Partner Slider One
            if ($(".partner_slider_one").length) {
                $('.partner_slider_one').slick({
                    centerPadding: '0px',
                    arrows: false,
                    dots: false,
                    slidesToShow: 5,
                    autoplay: true,
                    autoplaySpeed: 3000,
                    responsive: [
                        {
                            breakpoint: 992,
                            settings: {
                                slidesToShow: 4
                            }
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 3
                            }
                        },
                        {
                            breakpoint: 576,
                            settings: {
                                slidesToShow: 2
                            }
                        }
                    ]
                });
            }


            // ------------------------ Course Slider One
            if ($(".course_slider_one").length) {
                $('.course_slider_one').slick({
                    centerPadding: '0px',
                    arrows: true,
                    prevArrow: $('.prev_cs1'),
                    nextArrow: $('.next_cs1'),
                    dots: false,
                    slidesToShow: 4,
                    autoplay: true,
                    autoplaySpeed: 3000,
                    responsive: [
                        {
                            breakpoint: 992,
                            settings: {
                                slidesToShow: 2
                            }
                        },
                        {
                            breakpoint: 576,
                            settings: {
                                slidesToShow: 1
                            }
                        }
                    ]
                });
            }


            // ----------------------------- Animated Round Progressbar
            if ($(".circle_percent").length) {
                $(".circle_percent").each(function () {
                    var $this = $(this),
                        $dataV = $this.data("percent"),
                        $dataDeg = $dataV * 3.6,
                        $round = $this.find(".round_per");
                    $round.css("transform", "rotate(" + parseInt($dataDeg + 180) + "deg)");
                    $this.append('<div class="circle_inbox"><span class="percent_text"></span></div>');
                    $this.prop('Counter', 0).animate({ Counter: $dataV },
                        {
                            duration: 2000,
                            easing: 'swing',
                            step: function (now) {
                                $this.find(".percent_text").text(Math.ceil(now) + "%");
                            }
                        });
                    if ($dataV >= 51) {
                        $round.css("transform", "rotate(" + 360 + "deg)");
                        setTimeout(function () {
                            $this.addClass("percent_more");
                        }, 1000);
                        setTimeout(function () {
                            $round.css("transform", "rotate(" + parseInt($dataDeg + 180) + "deg)");
                        }, 1000);
                    }
                });
            };

            // ------------------------ Password Toggler
            if ($(".user-data-form").length) {
                $(".passVicon").on('click', function () {
                    $(".passVicon").toggleClass("eye-slash");
                    var input = $(".pass_log_id");
                    if (input.attr("type") === "password") {
                        input.attr("type", "text");
                    } else {
                        input.attr("type", "password");
                    }

                });
            }


            // ----------------------- Closes responsive menu when a scroll trigger link is clicked
            $('#one-page-nav .nav-link').on('click', function () {
                $('.navbar-collapse').removeClass('show');
                $('.navbar-toggler').attr("aria-expanded", "false");
            })



            // --------------------------------- Contact Form
            // init the validator
            // validator files are included in the download package
            // otherwise download from http://1000hz.github.io/bootstrap-validator

            if ($("#contact-form").length) {
                $('#contact-form').validator();
                // when the form is submitted
                $('#contact-form').on('submit', function (e) {

                    // if the validator does not prevent form submit
                    if (!e.isDefaultPrevented()) {
                        var url = "inc/contact.php";

                        // POST values in the background the the script URL
                        $.ajax({
                            type: "POST",
                            url: url,
                            data: $(this).serialize(),
                            success: function (data) {
                                // data = JSON object that contact.php returns

                                // we recieve the type of the message: success x danger and apply it to the
                                var messageAlert = 'alert-' + data.type;
                                var messageText = data.message;

                                // let's compose Bootstrap alert box HTML
                                var alertBox = '<div class="alert ' + messageAlert + ' alert-dismissable"><button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>' + messageText + '</div>';

                                // If we have messageAlert and messageText
                                if (messageAlert && messageText) {
                                    // inject the alert to .messages div in our form
                                    $('#contact-form').find('.messages').html(alertBox);
                                    // empty the form
                                    $('#contact-form')[0].reset();
                                }
                            }
                        });
                        return false;
                    }
                });
            }


            $(window).on('load', function () { // makes sure the whole site is loaded

                // -------------------- Site Preloader
                $('#ctn-preloader').fadeOut(); // will first fade out the loading animation
                $('#preloader').delay(350).fadeOut('slow'); // will fade out the white DIV that covers the website.
                $('body').delay(350).css({ 'overflow': 'visible' });



                // ------------------------------- Scroll Animation
                // var wow = new wow(
                //     {
                //         boxClass: 'wow',      // animated element css class (default is wow)
                //         animateClass: 'animated', // animation css class (default is animated)
                //         offset: 0,          // distance to the element when triggering the animation (default is 0)
                //         mobile: true,       // trigger animations on mobile devices (default is true)
                //         live: true,       // act on asynchronously loaded content (default is true)
                //     }
                // );
                // wow.init();

                // ------------------------------------- Fancybox
                var fancy = $(".fancybox");
                if (fancy.length) {
                    fancy.fancybox({
                        arrows: true,
                        buttons: [
                            "zoom",
                            //"share",
                            "slideShow",
                            //"fullScreen",
                            //"download",
                            "thumbs",
                            "close"
                        ],
                        animationEffect: "zoom-in-out",
                        transitionEffect: "zoom-in-out",
                    });
                }



                // ----------------------------- isotop gallery
                if ($("#isotop-gallery-wrapper").length) {
                    var $grid = $('#isotop-gallery-wrapper').isotope({
                        // options
                        itemSelector: '.isotop-item',
                        percentPosition: true,
                        masonry: {
                            // use element for option
                            columnWidth: '.grid-sizer'
                        }

                    });

                    // filter items on button click
                    $('.isotop-menu-wrapper').on('click', 'li', function () {
                        var filterValue = $(this).attr('data-filter');
                        $grid.isotope({ filter: filterValue });
                    });

                    // change is-checked class on buttons
                    $('.isotop-menu-wrapper').each(function (i, buttonGroup) {
                        var $buttonGroup = $(buttonGroup);
                        $buttonGroup.on('click', 'li', function () {
                            $buttonGroup.find('.is-checked').removeClass('is-checked');
                            $(this).addClass('is-checked');
                        });
                    });
                }


            });  //End On Load Function


        })($);
    }, [])
    const [isCollapsed, setIsCollapsed] = useState(true);

    const handleToggle = () => {
        setIsCollapsed(!isCollapsed);
    };
    const [isExpanded1, setIsExpanded1] = useState(false);

    const toggleAccordion1 = () => {
        setIsExpanded1(!isExpanded1);
        setIsExpanded2(false);
        setIsExpanded3(false);
        setIsExpanded4(false);
    };
    const [isExpanded2, setIsExpanded2] = useState(false);

    const toggleAccordion2 = () => {
        setIsExpanded2(!isExpanded2);
        setIsExpanded1(false);
        setIsExpanded3(false);
        setIsExpanded4(false);
    };
    const [isExpanded3, setIsExpanded3] = useState(false);

    const toggleAccordion3 = () => {
        setIsExpanded3(!isExpanded3);
        setIsExpanded2(false);
        setIsExpanded1(false);
        setIsExpanded4(false);
    };
    const [isExpanded4, setIsExpanded4] = useState(false);

    const toggleAccordion4 = () => {
        setIsExpanded4(!isExpanded4);
        setIsExpanded2(false);
        setIsExpanded3(false);
        setIsExpanded1(false);
    };
    const [isMobileView, setIsMobileView] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 821); // Adjust the breakpoint as needed
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <div>
            <header class="theme-main-menu sticky-menu theme-menu-three white-vr">
                <div class="inner-content position-relative">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="logo order-lg-0">
                            <a href="/home" class="d-block align-items-center">
                                <img src="images/logo/icon2.png" alt="" width="55" />
                            </a>
                            <a href="/home" class=" align-items-center">
                                <span style={{ fontWeight: 600, fontSize: 14, fontFamily: "Gordita", color: "#FFF" }}>
                                    Youth India<br /> E-School
                                </span>
                            </a>
                        </div>

                        <div class="right-widget d-flex align-items-center ms-auto ms-lg-0 order-lg-3">
                            <a href="https://sundergarh.youthindiaeschool.com/" class="login-btn-two fw-500 d-flex align-items-center me-3">
                                <img src="images/icon/icon_20.svg" alt="" class="me-2" />
                                <span>Log in</span>
                            </a>
                            <a href="https://calendly.com/pingaleshrikrushana/30min" class="signup-btn-one tran3s position-relative ps-3 d-none d-lg-block">Book a <span class="fw-500">demo</span> with us</a>
                        </div>

                        <nav class="navbar navbar-expand-lg order-lg-2">
                            <button
                                className="navbar-toggler"
                                type="button"
                                onClick={handleToggle}
                            >
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className={`collapse navbar-collapse ${isCollapsed ? '' : 'show'}`}>
                                <ul class="navbar-nav">
                                    <li class="d-block d-lg-none">
                                        <div class="logo">
                                            <a href="/home" class="d-block align-items-center">
                                                <img src="images/logo/icon2.png" alt="" width="55" />
                                            </a>
                                            <a href="/home" class=" align-items-center">
                                                <span style={{ fontWeight: 600, fontSize: 14, fontFamily: "Gordita", color: "#FFF" }}>
                                                    Youth India<br /> E-School
                                                </span>
                                            </a>
                                        </div>
                                    </li>
                                    <li class="nav-item dropdown mega-dropdown">
                                        <a class="nav-link" href="/home" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="true" style={{ color: "white" }}>Home</a>
                                    </li>
                                    <li class="nav-item dropdown mega-dropdown-md">
                                        <a class="nav-link " href="/aboutus" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" style={{ color: "white" }}>About</a>

                                    </li>
                                    <li class="nav-item dropdown">
                                        <a class="nav-link " href="/services" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" style={{ color: "white" }}>Services</a>

                                    </li>
                                    <li class="nav-item dropdown">
                                        <a class="nav-link" href="/highlights" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" style={{ color: "white" }}>Features</a>
                                    </li>
                                    <li class="nav-item dropdown">
                                        <a class="nav-link" href="/contactus" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" style={{ color: "white" }}>Contact</a>
                                    </li>
                                </ul>
                                <div class="mobile-content d-block d-lg-none">
                                    <div class="d-flex flex-column align-items-center justify-content-center mt-70">
                                        <a href="https://calendly.com/pingaleshrikrushana/30min" class="signup-btn-one tran3s position-relative">Book a <span class="fw-500">demo </span>with us</a>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </header>
            <div class="hero-banner-three position-relative pt-200 md-pt-150">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 wow fadeInLeft">
                            <h1 class="hero-heading fw-bold mt-45 mb-40 md-mt-20">E-Task Force.</h1>
                            <br />
                            {isMobileView === false ?
                                <p class="text-lg mb-50 lg-mb-30 pe-xxl-4">At the forefront of driving digital transformation in education.</p>
                                :
                                <p class="text-lg mb-50 lg-mb-30 pe-xxl-4" style={{ color: 'white' }}>At the forefront of driving digital transformation in education.</p>
                            }
                        </div>
                    </div>
                </div>
                <div class="mobile-screen wow fadeInRight">
                    <div class="justify-content-center align-items-center mb-50 mt-200">
                        <div class="col-10">
                            <img src="images/mobile/services.png" alt="" class="lazy-img img-one" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="fancy-feature-seven pt-200 lg-pt-100">
                <div class="container">
                    <div class="column align-items-center">
                        <div class="col-xl-6 col-lg-5 wow fadeInLeft">
                            <div class="title-style-four text-center text-lg-start">
                                <h2 class="main-title fw-500 tx-dark m0">What We <span>Offer.</span></h2>
                            </div>
                        </div>
                        <br />
                        <div class="col-lg-6  wow fadeInRight">
                            <p class="text-lg text-center text-lg-start md-pt-30 m0">
                                Providing a custom solution which meets the needs of the users is a key aspect of the project. Thus, we
                                shall work in collaboration with the district administration for the digitization and transformation needs of
                                the schools.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="container pt-50 lg-pt-10">
                    <div class="row gx-xxl-5">
                        <div class="col-lg-4 col-md-6 wow fadeInUp">
                            <div class="card-style-four text-center position-relative mt-40 xs-mt-20">
                                <div class="icon rounded-circle m-auto d-flex align-items-center justify-content-center" ><HiBriefcase size={100} color="#4e76a1" /></div>
                                <h4 class="fw-500 mt-35 mb-25"><a href="#" class="tran3s tx-dark">Project Management</a></h4>
                                <p class="mb-30">
                                    E-School shall work alongside the district administration in the
                                    implementation of the project. We shall ensure that the designated schools can start using the
                                    e-school model for their day to day operations.
                                </p>
                            </div>

                        </div>
                        <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
                            <div class="card-style-four text-center position-relative mt-40 xs-mt-20">
                                <div class="icon rounded-circle m-auto d-flex align-items-center justify-content-center" ><HiChatBubbleLeftRight size={100} color="#4e76a1" /></div>
                                <h4 class="fw-500 mt-35 mb-25"><a href="#" class="tran3s tx-dark">Consulting</a></h4>
                                <p class="mb-30">
                                    As a technology partner we shall provide advisory services to ensure
                                    implementation of the project. The timeline for the project shall be defined in consultation with
                                    the district administration. Certain aspects of the e-school model shall be customised to fit the
                                    requirement of the district administration to ensure optimal utilisation.
                                </p>
                            </div>

                        </div>
                        <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.4s">
                            <div class="card-style-four text-center position-relative mt-40 xs-mt-20">
                                <div class="icon rounded-circle m-auto d-flex align-items-center justify-content-center" ><HiBookOpen size={100} color="#4e76a1" /></div>
                                <h4 class="fw-500 mt-35 mb-25"><a href="#" class="tran3s tx-dark">Administration</a></h4>
                                <p class="mb-30">
                                    All aspects in the administration of the e-school project shall be managed in
                                    coordination with the district administration.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row gx-xxl-5">
                        <div class="col-lg-4 col-md-6 wow fadeInUp">
                            <div class="card-style-four text-center position-relative mt-40 xs-mt-20">
                                <div class="icon rounded-circle m-auto d-flex align-items-center justify-content-center" ><HiClipboardDocumentCheck size={100} color="#4e76a1" /></div>
                                <h4 class="fw-500 mt-35 mb-25"><a href="#" class="tran3s tx-dark">Policy Support</a></h4>
                                <p class="mb-30">
                                    Our advisory board shall help the district administration in strategy formulation
                                    for digitization and transformation of schools. The data from our systems can be further
                                    analysed to get key insights which shall help in defining policy matters.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.4s">
                            <div class="card-style-four text-center position-relative mt-40 xs-mt-20">
                                <div class="icon rounded-circle m-auto d-flex align-items-center justify-content-center" ><HiCurrencyRupee size={100} color="#4e76a1" /></div>
                                <h4 class="fw-500 mt-35 mb-25"><a href="#" class="tran3s tx-dark">Training and Capacity Building</a></h4>
                                <p class="mb-30">
                                    The task-force shall plan and schedule training for the
                                    school administration and teachers to introduce the latest teaching methodologies. The system
                                    use case training shall help in capacity building of schools to provide better tools to students.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
                            <div class="card-style-four text-center position-relative mt-40 xs-mt-20">
                                <div class="icon rounded-circle m-auto d-flex align-items-center justify-content-center"  ><HiPresentationChartLine size={100} color="#4e76a1" /></div>
                                <h4 class="fw-500 mt-35 mb-25"><a href="#" class="tran3s tx-dark">R&amp;D Pilots</a></h4>
                                <p class="mb-30">
                                    Our research team shall keep innovating to further improve the operations of
                                    schools with the use of technology and IoT. We shall run pilot projects in designated schools to
                                    check feasibility of certain digital solutions and share the findings with the district
                                    administration.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            
            <div class="fancy-short-banner-sixteen mt-130 lg-mt-100 pb-50 wow fadeInUp">
                <div class="container">
                    <div class="bg-wrapper pt-65 pb-65 lg-pt-40 lg-pb-40">
                        <div class="row">
                            <div class="col-xl-10 col-md-11 m-auto">
                                <div class="row align-items-center">
                                    <div class="col-lg-6">
                                        <div class="text-wrapper text-center text-lg-start md-pb-30">
                                            <div class="sc-title fs-18 pb-10" style={{ color: "#fff", fontWeight: 500 }}>Have Any Project?</div>
                                            <h2 class="main-title fw-500 text-white m0">Don’t hesitate to send us a message.</h2>
                                        </div>
                                    </div>
                                    <div class="col-lg-5 ms-auto text-center text-lg-end">
                                        <a href="/contactus" class="btn-twentyOne fw-500 tran3s">Get Started Today!</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )

}

export default TaskForce;