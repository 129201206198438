import React, { useState, useEffect } from "react";
import { IoIosArrowDroprightCircle, IoIosArrowDropleftCircle } from "react-icons/io";
import Footer from "./Footer.jsx"
import { Stack, Carousel } from 'react-bootstrap';
import $ from 'jquery';
import "jquery-ui-dist/jquery-ui"
function Testimonials() {
    const texts = [
        "The Eschool team is consistently active and enthusiastic in guiding the school staff. The video content provided is highly beneficial and greatly aids in the learning process. Students are able to easily access all portals and display a keen interest in watching the diverse range of videos available.", 
        "The Eschool team is incredibly friendly and makes frequent visits to our school, providing invaluable assistance in seamlessly digitizing our educational institution. The library is enriched with a vast collection of national and international content, empowering students to engage in enhanced learning experiences.",
        "The Eschool team exhibits remarkable cooperation in managing the Eschool facilities and offers us a superb platform for effective communication with parents and facilitating student learning."

    ];
    const users = ['Pratibha Kumari Mohapatra', 'Kalyani Panigrahi', 'Naresh Kumar Patel']
    const places = [
        "Govt. High School, Jhirpani",
        "Govt. High School, Kumjharia",
        "Girls High School, Ujalpur"
    ]
    const [currentIndex, setCurrentIndex] = useState(0);

    const goToNextSlide = () => {
        setCurrentIndex((currentIndex + 1) % texts.length);
    };

    const goToPreviousSlide = () => {
        setCurrentIndex((currentIndex - 1 + texts.length) % texts.length);
    };
    const [isMobileView, setIsMobileView] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 821); // Adjust the breakpoint as needed
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <div class="feedback-section-three position-relative mt-225 lg-mt-120  wow fadeInUp">
            <div class="container">
                <div class="row">
                    <div class="col-xl-5 col-lg-6 ms-auto">
                        <div class="title-style-four text-center text-lg-start pb-100 lg-pb-30">
                            <div class="sc-title-two" >Testimonials</div>
                            <h2 class="main-title fw-500 tx-dark m0">Discover what our clients have to <span>say.</span></h2>
                        </div>

                    </div>
                </div>
                <div class="slider-wrapper">
                    <div class="feedback_slider_three">
                        {
                            isMobileView === false ?
                                <Stack direction="horizontal">
                                    <div class="item">
                                        <div class="feedback-block-three">
                                            <ul class="style-none d-flex rating">
                                                <li><i class="bi bi-star-fill"></i></li>
                                                <li><i class="bi bi-star-fill"></i></li>
                                                <li><i class="bi bi-star-fill"></i></li>
                                                <li><i class="bi bi-star-fill"></i></li>
                                                <li><i class="bi bi-star-fill"></i></li>
                                            </ul>
                                            <p>{texts[currentIndex]}</p>
                                            <div class="d-flex align-items-center">
                                                <img src="images/team/dummy.jpg" alt="" class="avatar rounded-circle" />
                                                <h6 class="name fs-20 fw-500 m0 ps-4">{users[currentIndex]} <span class="d-block fw-normal">Headmaster</span><span class="d-block fw-normal">{places[currentIndex]}</span></h6>
                                                <img src="images/icon/icon_35.svg" alt="" class="ms-auto" />
                                            </div>
                                        </div>

                                    </div>

                                    <div class="item">
                                        <div class="feedback-block-three">
                                            <ul class="style-none d-flex rating">
                                                <li><i class="bi bi-star-fill"></i></li>
                                                <li><i class="bi bi-star-fill"></i></li>
                                                <li><i class="bi bi-star-fill"></i></li>
                                                <li><i class="bi bi-star-fill"></i></li>
                                                <li><i class="bi bi-star-fill"></i></li>
                                            </ul>
                                            <p>{texts[(currentIndex + 1) % texts.length]}</p>
                                            <div class="d-flex align-items-center">
                                                <img src="images/team/dummy.jpg" alt="" class="avatar rounded-circle" />
                                                <h6 class="name fs-20 fw-500 m0 ps-4">{users[(currentIndex + 1) % texts.length]}<span class="d-block fw-normal">Headmaster</span><span class="d-block fw-normal">{places[(currentIndex + 1) % texts.length]}</span></h6>
                                                <img src="images/icon/icon_35.svg" alt="" class="ms-auto" />
                                            </div>
                                        </div>

                                    </div>

                                    {/* <div class="item">
                                        <div class="feedback-block-three">
                                            <ul class="style-none d-flex rating">
                                                <li><i class="bi bi-star-fill"></i></li>
                                                <li><i class="bi bi-star-fill"></i></li>
                                                <li><i class="bi bi-star-fill"></i></li>
                                                <li><i class="bi bi-star-fill"></i></li>
                                                <li><i class="bi bi-star-fill"></i></li>
                                            </ul>
                                            <p>{texts[(currentIndex + 2) % texts.length]}</p>
                                            <div class="d-flex align-items-center">
                                                <img src="images/team/th.jpg" alt="" class="avatar rounded-circle" />
                                                <h6 class="name fs-20 fw-500 m0 ps-4">{users[(currentIndex + 2) % texts.length]}<span class="d-block fw-normal">England</span></h6>
                                                <img src="images/icon/icon_35.svg" alt="" class="ms-auto" />
                                            </div>
                                        </div>
                                    </div> */}
                                    <div>
                                        <button onClick={goToPreviousSlide}><IoIosArrowDropleftCircle size={50} color="#4e76a1" /></button>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <button onClick={goToNextSlide}><IoIosArrowDroprightCircle size={50} color="#4e76a1" /></button>
                                    </div>
                                </Stack>
                                :
                                <Stack>
                                    <div class="item">
                                        <div class="feedback-block-three">
                                            <ul class="style-none d-flex rating">
                                                <li><i class="bi bi-star-fill"></i></li>
                                                <li><i class="bi bi-star-fill"></i></li>
                                                <li><i class="bi bi-star-fill"></i></li>
                                                <li><i class="bi bi-star-fill"></i></li>
                                                <li><i class="bi bi-star-fill"></i></li>
                                            </ul>
                                            <p>{texts[currentIndex]}</p>
                                            <div class="d-flex align-items-center">
                                                <img src="images/team/dummy.jpg" alt="" class="avatar rounded-circle" />
                                                <h6 class="name fs-20 fw-500 m0 ps-4">{users[currentIndex]} <span class="d-block fw-normal">Headmaster</span><span class="d-block fw-normal">{places[currentIndex]}</span></h6>
                                                <img src="images/icon/icon_35.svg" alt="" class="ms-auto" />
                                            </div>
                                        </div>

                                    </div>
                                    <div class="d-flex align-items-center justify-content-center">
                                        <button onClick={goToPreviousSlide}><IoIosArrowDropleftCircle size={50} color="#4e76a1" /></button>
                                        <button onClick={goToNextSlide}><IoIosArrowDroprightCircle size={50} color="#4e76a1" /></button>
                                    </div>
                                </Stack>
                        }
                    </div>
                </div>

            </div>
            <div class="shape-holder">
                <img src="images/shape/shape_44.svg" alt="" class="lazy-img shapes shape-one" />
                {/* <img src="images/lazy.svg" data-src="images/media/img_22.jpg" alt="" class="lazy-img shapes rounded-circle avatar-one" />
                <img src="images/lazy.svg" data-src="images/media/img_23.jpg" alt="" class="lazy-img shapes rounded-circle avatar-two" />
                <img src="images/lazy.svg" data-src="images/media/img_24.jpg" alt="" class="lazy-img shapes rounded-circle avatar-three" />
                <img src="images/lazy.svg" data-src="images/media/img_25.jpg" alt="" class="lazy-img shapes rounded-circle avatar-four" /> */}
            </div>
        </div>
    )
}

export default Testimonials;