import React, { useState, useEffect } from "react";
import Footer from "./Footer.jsx"

function ContactUs() {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [isMobileView, setIsMobileView] = useState(false);
    const [name,setName] = useState("");
    const [email,setEmail] = useState("");
    const [mobile,setMobile] = useState("");
    const [message,setMessage] = useState("");
    const [city, setCity] = useState("");
    const [schoolName, setSchoolName] = useState("");
    const [address, setAddress] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitStatus, setSubmitStatus] = useState(null);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 821); // Adjust the breakpoint as needed
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleToggle = () => {
        setIsCollapsed(!isCollapsed);
    };
    return (
        <div>
            <header class="theme-main-menu sticky-menu theme-menu-three white-vr">
                <div class="inner-content position-relative">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="logo order-lg-0">
                            <a href="/home" class="d-block align-items-center">
                                <img src="images/logo/icon2.png" alt="" width="55" />
                            </a>
                            <a href="/home" class=" align-items-center">
                                <span style={{ fontWeight: 600, fontSize: 14, fontFamily: "Gordita", color: "#FFF" }}>
                                    Youth India<br /> E-School
                                </span>
                            </a>
                        </div>

                        <div class="right-widget d-flex align-items-center ms-auto ms-lg-0 order-lg-3">
                            <a href="https://sundergarh.youthindiaeschool.com/" class="login-btn-two fw-500 d-flex align-items-center me-3">
                                <img src="images/icon/icon_20.svg" alt="" class="me-2" />
                                <span>Log in</span>
                            </a>
                            <a href="https://calendly.com/pingaleshrikrushana/30min" class="signup-btn-one tran3s position-relative ps-3 d-none d-lg-block">Book a <span class="fw-500">demo</span> with us</a>
                        </div>

                        <nav class="navbar navbar-expand-lg order-lg-2">
                            <button
                                className="navbar-toggler"
                                type="button"
                                onClick={handleToggle}
                            >
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className={`collapse navbar-collapse ${isCollapsed ? '' : 'show'}`}>
                                <ul class="navbar-nav">
                                    <li class="d-block d-lg-none">
                                        <div class="logo">
                                            <a href="/home" class="d-block align-items-center">
                                                <img src="images/logo/icon2.png" alt="" width="55" />
                                            </a>
                                            <a href="/home" class=" align-items-center">
                                                <span style={{ fontWeight: 600, fontSize: 14, fontFamily: "Gordita", color: "#FFF" }}>
                                                    Youth India<br /> E-School
                                                </span>
                                            </a>
                                        </div>
                                    </li>
                                    <li class="nav-item dropdown mega-dropdown">
                                        <a class="nav-link" href="/home" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="true" style={{ color: "white" }}>Home</a>
                                    </li>
                                    <li class="nav-item dropdown mega-dropdown-md">
                                        <a class="nav-link " href="/aboutus" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" style={{ color: "white" }}>About</a>

                                    </li>
                                    <li class="nav-item dropdown">
                                        <a class="nav-link " href="/services" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" style={{ color: "white" }}>Services</a>

                                    </li>
                                    <li class="nav-item dropdown">
                                        <a class="nav-link" href="/highlights" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" style={{ color: "white" }}>Features</a>
                                    </li>
                                    <li class="nav-item dropdown">
                                        <a class="nav-link" href="/contactus" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" style={{ color: "white" }}>Contact</a>
                                    </li>
                                </ul>
                                <div class="mobile-content d-block d-lg-none">
                                    <div class="d-flex flex-column align-items-center justify-content-center mt-70">
                                        <a href="https://calendly.com/pingaleshrikrushana/30min" class="signup-btn-one tran3s position-relative">Book a <span class="fw-500">demo </span>with us</a>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </header>
            <div class="hero-banner-three position-relative pt-200 md-pt-150">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 wow fadeInLeft">
                            <h1 class="hero-heading fw-bold mt-45 mb-40 md-mt-20">Contact Us.</h1>
                            <br />

                            <p class="text-lg mb-50 lg-mb-30 pe-xxl-4">We're here to answer your questions and support your journey towards digital education.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container md-mt-10 md-mb-10">
                <div class="contact-section-two text-start mt-50 lg-mt-60 mb-50">
                    {
                        isMobileView === false ?

                        <div class="row">
                            <div class="col-xl-4 col-lg-5  wow fadeInRight">
                                <div class="form-style-three md-mb-0 wow fadeInLeft">
                                    <form>
                                        <div class="messages"></div>
                                        <div class="row controls">
                                            <div class="col-12">
                                                <div class="input-group-meta form-group mb-35">
                                                    <label class="d-block" for="" style={{ fontWeight: 500 }}>Name*</label>
                                                    <input type="text" placeholder="Enter your name" name="name" value={name} onChange={(e) => setName(e.target.value)} required data-error="Name is required." />
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>

                                            <div class="col-12">
                                                <div class="input-group-meta form-group mb-40">
                                                    <label class="d-block" for="" style={{ fontWeight: 500 }}>City*</label>
                                                    <input type="number" placeholder="Enter your City" name="name" value={city} onChange={(e) => setCity(e.target.value)} required data-error="Valid phone number is required." />
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div class="col-xl-4 col-lg-5  wow fadeInRight">
                                <div class="form-style-three md-mb-0 wow fadeInLeft">
                                    <form action="inc/contact.php" id="contact-form" data-toggle="validator">
                                        <div class="messages"></div>
                                        <div class="row controls">
                                            <div class="col-12">
                                                <div class="input-group-meta form-group mb-35">
                                                    <label class="d-block" for="" style={{ fontWeight: 500 }}>Email*</label>
                                                    <input type="email" placeholder="Enter your e-mail" name="email" value={email} onChange={(e) => setEmail(e.target.value)}  required data-error="Valid email is required." />
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>

                                            <div class="col-12">
                                                <div class="input-group-meta form-group mb-40">
                                                    <label class="d-block" for="" style={{ fontWeight: 500 }}>School Name</label>
                                                    <input type="text" placeholder="Enter your School Name" name="name" value={schoolName} onChange={(e) => setSchoolName(e.target.value)}  required data-error="Name is required." />
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>

                                            


                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div class="col-xl-4 col-lg-5  wow fadeInRight">
                                <div class="form-style-three md-mb-0 wow fadeInLeft">
                                    <form action="inc/contact.php" id="contact-form" data-toggle="validator">
                                        <div class="messages"></div>
                                        <div class="column controls">
                                            <div class="col-12">
                                                <div class="input-group-meta form-group mb-35">
                                                    <label class="d-block" for="" style={{ fontWeight: 500 }}>Mobile No*</label>
                                                    <input type="text" placeholder="Enter your Mobile no" name="number" value={mobile} onChange={(e) => setMobile(e.target.value)} required data-error="Name is required." />
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>

                                            <div class="col-12">
                                                <div class="input-group-meta form-group mb-40">
                                                    <label class="d-block" for="" style={{ fontWeight: 500 }}>Address</label>
                                                    <input type="text" placeholder="Enter your Address" name="name" value={address} onChange={(e) => setAddress(e.target.value)} required data-error="Valid phone number is required." />
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div class="col-xl-12 col-lg-5  wow fadeInRight">
                                <div class="form-style-three md-mb-0 wow fadeInLeft">
                                    <form action="inc/contact.php" id="contact-form" data-toggle="validator">
                                        <div class="messages"></div>
                                        <div class="column controls">

                                            <div class="col-12">
                                                <div class="input-group-meta form-group mb-30">
                                                    <label class="d-block" for="" style={{ fontWeight: 500 }}>Message*</label>
                                                    <textarea placeholder="Enter your message" name="message" value={message} onChange={(e) => setMessage(e.target.value)} required data-error="Please,leave us a message." cols="40"></textarea>
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="col-12"><button class="btn-one fw-500 w-100 text-uppercase fs-14 d-block" type="submit" disabled={isSubmitting} >Contact Now</button></div>
                        </div>
                        :
                        <div class="row">
                            <div class="col-xl-4 col-lg-5  wow fadeInRight">
                                <div class="form-style-three md-mb-0 wow fadeInLeft">
                                    <form action="inc/contact.php" id="contact-form" data-toggle="validator">
                                        <div class="messages"></div>
                                        <div class="row controls">
                                            <div class="col-12">
                                                <div class="input-group-meta form-group mb-35">
                                                    <label class="d-block" for="" style={{ fontWeight: 500 }}>Name*</label>
                                                    <input type="text" placeholder="Enter your name" name="name" required="required" data-error="Name is required." />
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>

                                            <div class="col-12">
                                                <div class="input-group-meta form-group mb-40">
                                                    <label class="d-block" for="" style={{ fontWeight: 500 }}>Email*</label>
                                                    <input type="email" placeholder="Enter your e-mail" name="email" required="required" data-error="Valid email is required." />
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div class="col-xl-4 col-lg-5  wow fadeInRight">
                                <div class="form-style-three md-mb-0 wow fadeInLeft">
                                    <form action="inc/contact.php" id="contact-form" data-toggle="validator">
                                        <div class="messages"></div>
                                        <div class="row controls">

                                            <div class="col-12">
                                                <div class="input-group-meta form-group mb-35">
                                                    <label class="d-block" for="" style={{ fontWeight: 500 }}>Mobile No*</label>
                                                    <input type="text" placeholder="Enter your Mobile no" name="number" required="required" data-error="Name is required." />
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>

                                            <div class="col-12">
                                                <div class="input-group-meta form-group mb-40">
                                                    <label class="d-block" for="" style={{ fontWeight: 500 }}>City*</label>
                                                    <input type="number" placeholder="Enter your City" name="name" required="required" data-error="Valid phone number is required." />
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div class="col-xl-4 col-lg-5  wow fadeInRight">
                                <div class="form-style-three md-mb-0 wow fadeInLeft">
                                    <form action="inc/contact.php" id="contact-form" data-toggle="validator">
                                        <div class="messages"></div>
                                        <div class="column controls">
                                            
                                            <div class="col-12">
                                                <div class="input-group-meta form-group mb-35">
                                                    <label class="d-block" for="" style={{ fontWeight: 500 }}>School Name</label>
                                                    <input type="text" placeholder="Enter your School Name" name="name" required="required" data-error="Name is required." />
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>

                                            <div class="col-12">
                                                <div class="input-group-meta form-group mb-40">
                                                    <label class="d-block" for="" style={{ fontWeight: 500 }}>Address</label>
                                                    <input type="text" placeholder="Enter your Address" name="name" required="required" data-error="Valid phone number is required." />
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div class="col-xl-12 col-lg-5  wow fadeInRight">
                                <div class="form-style-three md-mb-0 wow fadeInLeft">
                                    <form action="inc/contact.php" id="contact-form" data-toggle="validator">
                                        <div class="messages"></div>
                                        <div class="column controls">

                                            <div class="col-12">
                                                <div class="input-group-meta form-group mb-30">
                                                    <label class="d-block" for="" style={{ fontWeight: 500 }}>Message*</label>
                                                    <textarea placeholder="Enter your message" name="message" required="required" data-error="Please,leave us a message." cols="40"></textarea>
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="col-12"><button class="btn-one fw-500 w-100 text-uppercase fs-14 d-block">Contact Now</button></div>
                        </div>

                    }
                    

                </div>
            </div>

            {/* <div class="container">
                <div class="contact-section-two text-start mt-80 lg-mt-60 mb-50">
                    <div class="row">
                        <div class="col-lg-7">
                            <div class="form-style-three md-mb-60 wow fadeInLeft">
                                <form action="inc/contact.php" id="contact-form" data-toggle="validator">
                                    <div class="messages"></div>
                                    <div class="row controls">
                                        <div class="col-12">
                                            <div class="input-group-meta form-group mb-35">
                                                <label class="d-block" for="" style={{ fontWeight: 500 }}>Name*</label>
                                                <input type="text" placeholder="Enter your name" name="name" required="required" data-error="Name is required." />
                                                <div class="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div class="col-12">
                                            <div class="input-group-meta form-group mb-40">
                                                <label class="d-block" for="" style={{ fontWeight: 500 }}>Email*</label>
                                                <input type="email" placeholder="Enter your e-mail" name="email" required="required" data-error="Valid email is required." />
                                                <div class="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div class="col-12">
                                            <div class="input-group-meta form-group mb-40">
                                                <label class="d-block" for="" style={{ fontWeight: 500 }}>Contact Number*</label>
                                                <input type="number" placeholder="Enter your contact number" name="number" required="required" data-error="Valid phone number is required." />
                                                <div class="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div class="col-12">
                                            <div class="input-group-meta form-group mb-30">
                                                <label class="d-block" for="" style={{ fontWeight: 500 }}>Message*</label>
                                                <textarea placeholder="Enter your message" name="message" required="required" data-error="Please,leave us a message."></textarea>
                                                <div class="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div class="col-12"><button class="btn-one fw-500 w-100 text-uppercase fs-14 d-block">Send Message</button></div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div class="col-xl-4 col-lg-5 ms-auto wow fadeInRight">
                            <div class="address-block-three d-flex mb-70 lg-mb-40 mt-20">
                                <div class="icon"><img src="../images/icon/icon_161.svg" alt="" /></div>
                                <div class="text">
                                    <h5 class="title">Our Address</h5>
                                    <p>11th Floor, 1117 Devika Tower, <br />Nehru Place, New Delhi</p>
                                </div>
                            </div>
                            <div class="address-block-three d-flex mb-70 lg-mb-40">
                                <div class="icon"><img src="images/icon/icon_163.svg" alt="" /></div>
                                <div class="text">
                                    <h5 class="title">Contact Info</h5>
                                    <p>Open a chat or give us call at <br />+91-7848939373<br />+91-9582961770</p>
                                </div>
                            </div>
                            <div class="address-block-three d-flex">
                                <div class="icon"><img src="images/icon/icon_162.svg" alt="" /></div>
                                <div class="text">
                                    <h5 class="title">E-mail Info</h5>
                                    <p>Reach us at <br /> info@youthindiaeschool.com</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* <div class="fancy-short-banner-sixteen mt-130 lg-mt-100 wow fadeInUp">
                    <div class="container">
                        <div class="bg-wrapper pt-65 pb-65 lg-pt-40 lg-pb-40">
                            <div class="row">
                                <div class="col-xl-10 col-md-11 m-auto">
                                    <div class="row align-items-center">
                                        <div class="col-lg-6">
                                            <div class="text-wrapper text-center text-lg-start md-pb-30">
                                                <div class="sc-title fs-18 pb-10" style={{color: "#fff", fontWeight: 500}}>Have Any Project?</div>
                                                <h2 class="main-title fw-500 text-white m0">Don’t hesitate to send us a message.</h2>
                                            </div>
                                        </div>
                                        <div class="col-lg-5 ms-auto text-center text-lg-end">
                                            <a href="contact-us.html" class="btn-twentyOne fw-500 tran3s">Get Started Today!</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div> */}

            <Footer />
        </div>
    )
}

export default ContactUs;