import React, { useState, useEffect } from "react";
import USP from "./USP";
import { BsCheckCircleFill } from "react-icons/bs";
import { Stack, Carousel } from 'react-bootstrap';
import Testimonials from "./Testimonials";
import Marquee from "react-fast-marquee";

function Home() {
    const [isMobileView, setIsMobileView] = useState(false);
    const [name,setName] = useState("");
    const [email,setEmail] = useState("");
    const [mobile,setMobile] = useState("");
    const [message,setMessage] = useState("");
    const [city, setCity] = useState("");
    const [schoolName, setSchoolName] = useState("");
    const [address, setAddress] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitStatus, setSubmitStatus] = useState(null);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 821); // Adjust the breakpoint as needed
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <div>
            <div class="hero-banner-three position-relative pt-200 md-pt-150">
                <div class="container" >
                    <div class="row">
                        <div class="col-lg-6 wow fadeInLeft">
                            <h1 class="hero-heading fw-bold mt-45 mb-40 md-mt-20">Transforming Education.</h1>
                            {
                                isMobileView === false ?
                                    <p class="text-lg mb-50 lg-mb-30 pe-xxl-4">Next-Generation Education Management: Streamlined and Comprehensive Solution</p>
                                    :
                                    <p class="text-lg mb-50 lg-mb-30 pe-xxl-4" style={{ color: "white" }}>Next-Generation Education Management: Streamlined and Comprehensive Solution</p>
                            }
                            <div class="d-sm-flex align-items-center">
                                <a class="fancybox video-icon tran3s mb-25 d-flex align-items-center order-sm-last" data-fancybox="" href="https://www.youtube.com/embed/96ijxEmLTNA">
                                    <i class="bi bi-play"></i>
                                    <div class="ps-3">
                                        {
                                            isMobileView === false ?
                                                <span class="d-block fs-15 text-uppercase">Watch</span>
                                                :
                                                <span class="d-block fs-15 text-uppercase" style={{ color: "black" }}>Watch</span>
                                        }
                                        <strong class="fs-18 fw-normal tx-dark d-block">Intro Video</strong>
                                    </div>
                                </a>
                                <div class="dropdown download-btn d-inline-block mb-25 me-4 order-sm-first">
                                    <a href="https://play.google.com/store/apps/details?id=com.shrikrushana.Eschool">
                                        <button type="button">
                                            Download
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mobile-screen wow fadeInRight" >
                    <div class="justify-content-center align-items-center mb-50">
                        <Stack direction="horizontal">
                            <div class="col-6">
                                <img src="images/mobile/image6.png" alt="" class="lazy-img screen-one" />
                            </div>
                            <div class="col-6" style={{ paddingLeft: "10px" }}>
                                {
                                    isMobileView === false ?
                                        <div class="card-one d-flex align-items-center mb-50 xs-mb-20">
                                            <div style={{ justifyContent: "center" }}><BsCheckCircleFill size={40} color={"#4E76A1"} /></div>
                                            <h6 class="fw-500 fs-20 m0" style={{ paddingLeft: "15px" }}>Redefining Schooling</h6>
                                        </div>
                                        :
                                        <div class="card-one d-flex justify-content-center mb-50 xs-mb-20" style={{ justifyContent: "center", alignItems: "center" }}>
                                            <div style={{ justifyContent: "center" }}><BsCheckCircleFill size={40} color={"#4E76A1"} /></div>
                                            <div style={{ justifyContent: "center" }}><h6 class="fw-500 fs-20 m0 ms-auto" style={{ paddingLeft: "10px", alignItems: "center" }}>Redefining Schooling</h6></div>
                                        </div>
                                }
                                <img src="images/mobile/image7.png" alt="" class="lazy-img screen-two" />
                            </div>
                        </Stack>
                    </div>
                </div>

            </div>


            <div class="partner-section-two position-relative mb-250 md-mt-90 md-m0" style={{marginTop: "350px", backgroundColor:"#fff"}}>
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-xl-5 col-lg-6 wow fadeInLeft" style={{ visibility: "visible", animationName: "fadeInLeft" }}>
                            <div class="title-style-four">
                                {/* <!-- <div class="sc-title-two" style="color:#6A45FF;">Our Partners</div> --> */}
                                {/* <h2 class="main-title fw-500 tx-dark m0" style={{ fontFamily: "gordita" }}>As featured on <br />Times Of <span>India.</span></h2> */}
                                <h2 class="main-title fw-500 tx-dark m0" style={{ fontFamily: "gordita" }}><span>Times of India</span> Shines the Spotlight on Us!</h2>
                            </div>
                            <p class="fs-20 pt-30 pe-xxl-5">A unique initiative by Sundergarh administration to make classroom teaching accessible to every student in the 
                                tribal-dominated district with the help of technology has received positive feedback from pupils and the teachers... 
                                <a href="https://timesofindia.indiatimes.com/city/bhubaneswar/e-school-initiative-in-sgarh-wins-praise/articleshow/99228589.cms"> read more.</a>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="illustration-holder">
                    <img src="images/mobile/image8.png" alt="" class="lazy-img main-img" />
                </div>
            </div>
            
            <div class="md-p10">
                <USP />
            </div>
            
            <Testimonials />

            <div class="partner-section-two position-relative mb-10 md-mt-100 md-m0" style={{marginTop: "100px", backgroundColor:"#fff"}}>
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-xl-5 col-lg-6 wow fadeInLeft" style={{ visibility: "visible", animationName: "fadeInLeft" }}>
                            <div class="title-style-four">
                                {/* <!-- <div class="sc-title-two" style="color:#6A45FF;">Our Partners</div> --> */}
                                {/* <h2 class="main-title fw-500 tx-dark m0" style={{ fontFamily: "gordita" }}>As featured on <br />Times Of <span>India.</span></h2> */}
                                <h2 class="main-title fw-500 tx-dark m0" style={{ fontFamily: "gordita" }}>Partner with <span>us.</span></h2>
                            </div>
                            <p class="fs-20 pt-30 pe-xxl-5">
                                If you share our vision of transforming learning experiences and empowering educational institutions, we invite you to partner with us.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container md-mt-0 md-mb-100">
                <div class="contact-section-two text-start mt-50 lg-mt-60 mb-50">
                    {
                        isMobileView === false ?

                        <div class="row">
                            <div class="col-xl-4 col-lg-5  wow fadeInRight">
                                <div class="form-style-three md-mb-0 wow fadeInLeft">
                                    <form>
                                        <div class="messages"></div>
                                        <div class="row controls">
                                            <div class="col-12">
                                                <div class="input-group-meta form-group mb-35">
                                                    <label class="d-block" for="" style={{ fontWeight: 500 }}>Name*</label>
                                                    <input type="text" placeholder="Enter your name" name="name" value={name} onChange={(e) => setName(e.target.value)} required data-error="Name is required." />
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>

                                            

                                            <div class="col-12">
                                                <div class="input-group-meta form-group mb-40">
                                                    <label class="d-block" for="" style={{ fontWeight: 500 }}>City*</label>
                                                    <input type="number" placeholder="Enter your City" name="name" value={city} onChange={(e) => setCity(e.target.value)} required data-error="Valid phone number is required." />
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div class="col-xl-4 col-lg-5  wow fadeInRight">
                                <div class="form-style-three md-mb-0 wow fadeInLeft">
                                    <form action="inc/contact.php" id="contact-form" data-toggle="validator">
                                        <div class="messages"></div>
                                        <div class="row controls">
                                            <div class="col-12">
                                                <div class="input-group-meta form-group mb-35">
                                                    <label class="d-block" for="" style={{ fontWeight: 500 }}>Email*</label>
                                                    <input type="email" placeholder="Enter your e-mail" name="email" value={email} onChange={(e) => setEmail(e.target.value)}  required data-error="Valid email is required." />
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>

                                            <div class="col-12">
                                                <div class="input-group-meta form-group mb-40">
                                                    <label class="d-block" for="" style={{ fontWeight: 500 }}>School Name</label>
                                                    <input type="text" placeholder="Enter your School Name" name="name" value={schoolName} onChange={(e) => setSchoolName(e.target.value)}  required data-error="Name is required." />
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>

                                            


                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div class="col-xl-4 col-lg-5  wow fadeInRight">
                                <div class="form-style-three md-mb-0 wow fadeInLeft">
                                    <form action="inc/contact.php" id="contact-form" data-toggle="validator">
                                        <div class="messages"></div>
                                        <div class="column controls">
                                            <div class="col-12">
                                                <div class="input-group-meta form-group mb-35">
                                                    <label class="d-block" for="" style={{ fontWeight: 500 }}>Mobile No*</label>
                                                    <input type="text" placeholder="Enter your Mobile no" name="number" value={mobile} onChange={(e) => setMobile(e.target.value)} required data-error="Name is required." />
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>

                                            <div class="col-12">
                                                <div class="input-group-meta form-group mb-40">
                                                    <label class="d-block" for="" style={{ fontWeight: 500 }}>Address</label>
                                                    <input type="text" placeholder="Enter your Address" name="name" value={address} onChange={(e) => setAddress(e.target.value)} required data-error="Valid phone number is required." />
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div class="col-xl-12 col-lg-5  wow fadeInRight">
                                <div class="form-style-three md-mb-0 wow fadeInLeft">
                                    <form action="inc/contact.php" id="contact-form" data-toggle="validator">
                                        <div class="messages"></div>
                                        <div class="column controls">

                                            <div class="col-12">
                                                <div class="input-group-meta form-group mb-30">
                                                    <label class="d-block" for="" style={{ fontWeight: 500 }}>Message*</label>
                                                    <textarea placeholder="Enter your message" name="message" value={message} onChange={(e) => setMessage(e.target.value)} required data-error="Please,leave us a message." cols="40"></textarea>
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="col-12"><button class="btn-one fw-500 w-100 text-uppercase fs-14 d-block" type="submit" disabled={isSubmitting} >Contact Now</button></div>
                        </div>
                        :
                        <div class="row">
                            <div class="col-xl-4 col-lg-5  wow fadeInRight">
                                <div class="form-style-three md-mb-0 wow fadeInLeft">
                                    <form action="inc/contact.php" id="contact-form" data-toggle="validator">
                                        <div class="messages"></div>
                                        <div class="row controls">
                                            <div class="col-12">
                                                <div class="input-group-meta form-group mb-35">
                                                    <label class="d-block" for="" style={{ fontWeight: 500 }}>Name*</label>
                                                    <input type="text" placeholder="Enter your name" name="name" required="required" data-error="Name is required." />
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>

                                            <div class="col-12">
                                                <div class="input-group-meta form-group mb-40">
                                                    <label class="d-block" for="" style={{ fontWeight: 500 }}>Email*</label>
                                                    <input type="email" placeholder="Enter your e-mail" name="email" required="required" data-error="Valid email is required." />
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div class="col-xl-4 col-lg-5  wow fadeInRight">
                                <div class="form-style-three md-mb-0 wow fadeInLeft">
                                    <form action="inc/contact.php" id="contact-form" data-toggle="validator">
                                        <div class="messages"></div>
                                        <div class="row controls">

                                            <div class="col-12">
                                                <div class="input-group-meta form-group mb-35">
                                                    <label class="d-block" for="" style={{ fontWeight: 500 }}>Mobile No*</label>
                                                    <input type="text" placeholder="Enter your Mobile no" name="number" required="required" data-error="Name is required." />
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>

                                            <div class="col-12">
                                                <div class="input-group-meta form-group mb-40">
                                                    <label class="d-block" for="" style={{ fontWeight: 500 }}>City*</label>
                                                    <input type="number" placeholder="Enter your City" name="name" required="required" data-error="Valid phone number is required." />
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div class="col-xl-4 col-lg-5  wow fadeInRight">
                                <div class="form-style-three md-mb-0 wow fadeInLeft">
                                    <form action="inc/contact.php" id="contact-form" data-toggle="validator">
                                        <div class="messages"></div>
                                        <div class="column controls">
                                            
                                            <div class="col-12">
                                                <div class="input-group-meta form-group mb-35">
                                                    <label class="d-block" for="" style={{ fontWeight: 500 }}>School Name</label>
                                                    <input type="text" placeholder="Enter your School Name" name="name" required="required" data-error="Name is required." />
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>

                                            <div class="col-12">
                                                <div class="input-group-meta form-group mb-40">
                                                    <label class="d-block" for="" style={{ fontWeight: 500 }}>Address</label>
                                                    <input type="text" placeholder="Enter your Address" name="name" required="required" data-error="Valid phone number is required." />
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div class="col-xl-12 col-lg-5  wow fadeInRight">
                                <div class="form-style-three md-mb-0 wow fadeInLeft">
                                    <form action="inc/contact.php" id="contact-form" data-toggle="validator">
                                        <div class="messages"></div>
                                        <div class="column controls">

                                            <div class="col-12">
                                                <div class="input-group-meta form-group mb-30">
                                                    <label class="d-block" for="" style={{ fontWeight: 500 }}>Message*</label>
                                                    <textarea placeholder="Enter your message" name="message" required="required" data-error="Please,leave us a message." cols="40"></textarea>
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="col-12"><button class="btn-one fw-500 w-100 text-uppercase fs-14 d-block">Contact Now</button></div>
                        </div>

                    }
                    

                </div>
            </div>

            <div class="partner-section-two position-relative mt-225 mb-250 md-mt-10 md-mb-10">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-xl-5 col-lg-6 wow fadeInLeft" style={{ visibility: "visible", animationName: "fadeInLeft" }}>
                            <div class="title-style-four">
                                {/* <!-- <div class="sc-title-two" style="color:#6A45FF;">Our Partners</div> --> */}
                                <h2 class="main-title fw-500 tx-dark m0" style={{ fontFamily: "gordita" }}>Our Clients & <span>Partners.</span></h2>
                            </div>
                            <p class="fs-20 pt-30 pe-xxl-5">Join our network of esteemed clients and partners shaping the future of education.</p>
                        </div>
                    </div>
                </div>
                <div class="logo-wrapper d-flex flex-wrap">
                    
                    {
                        isMobileView === false ?
                        <Marquee gradient="true" speed={100}>
                            <div class=" d-flex" style={{justifyContent:"space-between"}} >
                                <img src="images/partners/auro.png" alt="" class="lazy-img" style={{marginRight:"35px", marginLeft:"35px"}} />
                                <img src="images/partners/districtAdmin.jpg" alt="" class="lazy-img" style={{marginRight:"35px", marginLeft:"35px"}}/>
                                <img src="images/partners/Moschool.jpg" alt="" class="lazy-img" style={{marginRight:"35px", marginLeft:"35px"}}/>
                                <img src="images/partners/vedanta.jpg" alt="" class="lazy-img" style={{ marginLeft:"35px", marginRight:"35px"}}/>
                            </div>
                        </Marquee>
                        :
                        <Marquee speed={100}>
                            <div class=" d-flex" style={{justifyContent:"space-between"}} >
                                <img src="images/partners/auro.png" alt="" class="lazy-img" style={{marginRight:"35px", marginLeft:"35px"}} />
                                <img src="images/partners/districtAdmin.jpg" alt="" class="lazy-img" style={{marginRight:"35px", marginLeft:"35px"}}/>
                                <img src="images/partners/Moschool.jpg" alt="" class="lazy-img" style={{marginRight:"35px", marginLeft:"35px"}}/>
                                <img src="images/partners/vedanta.jpg" alt="" class="lazy-img" style={{ marginLeft:"35px", marginRight:"35px"}}/>
                            </div>
                        </Marquee>
                    }
                    
                    {/* <div class="logo d-flex align-items-center justify-content-center"><img src="images/partners/auro.png" alt="" width="90" class="lazy-img" /></div>
                        <div class="logo d-flex align-items-center justify-content-center"><img src="images/partners/districtAdmin.jpg" alt="" width="70" class="lazy-img" /></div>
                        <div class="logo d-flex align-items-center justify-content-center"><img src="images/partners/Moschool.jpg" alt="" width="90" class="lazy-img" /></div>
                        <div class="logo d-flex align-items-center justify-content-center"><img src="images/partners/vedanta.jpg" alt="" width="80" class="lazy-img" /></div> */}
                    {/* <div class="logo d-flex align-items-center justify-content-center"><img src="images/logo/Plogo-11.png" alt="" class="lazy-img" /></div>
                        <div class="logo d-flex align-items-center justify-content-center"><img src="images/logo/Plogo-12.png" alt="" class="lazy-img" /></div>
                        <div class="logo d-flex align-items-center justify-content-center"><img src="images/logo/Plogo-13.png" alt="" class="lazy-img" /></div> */}
                </div>
            </div>
            {/* <div class="fancy-short-banner-sixteen mt-130 lg-mt-100 pb-50 wow fadeInUp">
                <div class="container">
                    <div class="bg-wrapper pt-65 pb-65 lg-pt-40 lg-pb-40">
                        <div class="row">
                            <div class="col-xl-10 col-md-11 m-auto">
                                <div class="row align-items-center">
                                    <div class="col-lg-6">
                                        <div class="text-wrapper text-center text-lg-start md-pb-30">
                                            <div class="sc-title fs-18 pb-10" style={{ color: "#fff", fontWeight: 500 }}>Have Any Project?</div>
                                            <h2 class="main-title fw-500 text-white m0">Don’t hesitate to send us a message.</h2>
                                        </div>
                                    </div>
                                    <div class="col-lg-5 ms-auto text-center text-lg-end">
                                        <a href="/contactus" class="btn-twentyOne fw-500 tran3s">Get Started Today!</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div >
    )
}

export default Home;