import React, { useState, useEffect } from "react";
import USP from "./USP.jsx";
import Footer from "./Footer.jsx"
import { FaUsers, FaBrain, FaHandshake } from "react-icons/fa";
// import { FaSchool } from "react-icons/fa6";
import { IoIosSchool } from "react-icons/io";

function Highlights() {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const handleToggle = () => {
        setIsCollapsed(!isCollapsed);
    };
    const [isMobileView, setIsMobileView] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 821); // Adjust the breakpoint as needed
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        // <div class="fancy-feature-nine position-relative pt-250 lg-pt-130">
        //     <div class="container">
        //         <div class="row align-items-center">
        //             <div class="col-lg-5">
        //                 <div class="block-style-three wow fadeInLeft">
        //                     <div class="title-style-four">
        //                         <h2 class="main-title fw-500 tx-dark m0">Why would you choose <span>us?</span></h2>
        //                     </div>

        //                     <p class="fs-20 pt-45 pe-xxl-5">Youth India E-School aims to make education more accessible than ever before by digitisation of all school operations and bringing all stakeholders together on a single platform. Our ecosystem software empowers educators and
        //                         school in seamless delivery of education to the future generation.</p>
        //                 </div>

        //             </div>
        //             <div class="col-lg-7 col-md-9 m-auto text-center text-lg-right wow fadeInRight">
        //                 <div class="screen-container position-relative ms-auto">
        //                     <img src="images/lazy.svg" data-src="images/shape/shape_45.svg" alt="" class="lazy-img shapes bg-round-shape" />
        //                     <div class="block-content">
        //                         <div class="row gx-xxl-5 align-items-center">
        //                             <div class="col-sm-6">
        //                                 <div class="card-style-five wow fadeInDown" data-wow-delay="0.2s">
        //                                     <div class="icon d-flex align-items-end"><img src="images/lazy.svg" data-src="images/icon/icon_27.svg" alt="" class="lazy-img" /></div>
        //                                     <h4>16,000+ Users</h4>
        //                                 </div>

        //                                 <div class="card-style-five xs-mt-10 wow fadeInRight" data-wow-delay="0.2s">
        //                                     <div class="icon d-flex align-items-end"><img src="images/lazy.svg" data-src="images/icon/icon_29.svg" alt="" class="lazy-img" /></div>
        //                                     <h4>Multiple Partnerships</h4>
        //                                 </div>


        //                             </div>
        //                             <div class="col-sm-6">
        //                                 <div class="card-style-five xs-mt-10 wow fadeInRight" data-wow-delay="0.2s">
        //                                     <div class="icon d-flex align-items-end"><img src="images/lazy.svg" data-src="images/icon/icon_29.svg" alt="" class="lazy-img" /></div>
        //                                     <h4>Expert Masterclass</h4>
        //                                 </div>
        //                                 <div class="card-style-five wow fadeInUp" data-wow-delay="0.2s">
        //                                     <div class="icon d-flex align-items-end"><img src="images/lazy.svg" data-src="images/icon/icon_28.svg" alt="" class="lazy-img" /></div>
        //                                     <h4>50 Schools</h4>
        //                                 </div>

        //                             </div>
        //                         </div>
        //                     </div>

        //                 </div>

        //             </div>
        //         </div>
        //     </div>

        // </div>
        <div>
            <header class="theme-main-menu sticky-menu theme-menu-three white-vr">
                <div class="inner-content position-relative">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="logo order-lg-0">
                            <a href="/home" class="d-block align-items-center">
                                <img src="images/logo/icon2.png" alt="" width="55" />
                            </a>
                            <a href="/home" class=" align-items-center">
                                <span style={{ fontWeight: 600, fontSize: 14, fontFamily: "Gordita", color: "#FFF" }}>
                                    Youth India<br /> E-School
                                </span>
                            </a>
                        </div>

                        <div class="right-widget d-flex align-items-center ms-auto ms-lg-0 order-lg-3">
                            <a href="https://sundergarh.youthindiaeschool.com/" class="login-btn-two fw-500 d-flex align-items-center me-3">
                                <img src="images/icon/icon_20.svg" alt="" class="me-2" />
                                <span>Log in</span>
                            </a>
                            <a href="https://calendly.com/pingaleshrikrushana/30min" class="signup-btn-one tran3s position-relative ps-3 d-none d-lg-block">Book a <span class="fw-500">demo</span> with us</a>
                        </div>

                        <nav class="navbar navbar-expand-lg order-lg-2">
                            <button
                                className="navbar-toggler"
                                type="button"
                                onClick={handleToggle}
                            >
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className={`collapse navbar-collapse ${isCollapsed ? '' : 'show'}`}>
                                <ul class="navbar-nav">
                                    <li class="d-block d-lg-none">
                                        <div class="logo">
                                            <a href="/home" class="d-block align-items-center">
                                                <img src="images/logo/icon2.png" alt="" width="55" />
                                            </a>
                                            <a href="/home" class=" align-items-center">
                                                <span style={{ fontWeight: 600, fontSize: 14, fontFamily: "Gordita", color: "#FFF" }}>
                                                    Youth India<br /> E-School
                                                </span>
                                            </a>
                                        </div>
                                    </li>
                                    <li class="nav-item dropdown mega-dropdown">
                                        <a class="nav-link" href="/home" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="true" style={{ color: "white" }}>Home</a>
                                    </li>
                                    <li class="nav-item dropdown mega-dropdown-md">
                                        <a class="nav-link " href="/aboutus" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" style={{ color: "white" }}>About</a>

                                    </li>
                                    <li class="nav-item dropdown">
                                        <a class="nav-link " href="/services" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" style={{ color: "white" }}>Services</a>

                                    </li>
                                    <li class="nav-item dropdown">
                                        <a class="nav-link" href="/highlights" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" style={{ color: "white" }}>Features</a>
                                    </li>
                                    <li class="nav-item dropdown">
                                        <a class="nav-link" href="/contactus" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" style={{ color: "white" }}>Contact</a>
                                    </li>
                                </ul>
                                <div class="mobile-content d-block d-lg-none">
                                    <div class="d-flex flex-column align-items-center justify-content-center mt-70">
                                        <a href="https://calendly.com/pingaleshrikrushana/30min" class="signup-btn-one tran3s position-relative">Book a <span class="fw-500">demo </span>with us</a>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </header>
            <div class="hero-banner-three position-relative pt-200 md-pt-150">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 wow fadeInLeft">
                            <h1 class="hero-heading fw-bold mt-45 mb-40 md-mt-20">Why Would You Choose Us?</h1>
                            <br />
                            {
                                isMobileView === false ?
                                    <p class="text-lg mb-50 lg-mb-30 pe-xxl-4">Discover the features that make our platform a game-changer.</p>
                                    :
                                    <p class="text-lg mb-50 lg-mb-30 pe-xxl-4" style={{ color: 'white' }}>Discover the features that make our platform a game-changer.</p>
                            }
                        </div>
                    </div>
                </div>
                <div class="mobile-screen wow fadeInRight">
                    <div class="justify-content-center align-items-center mb-50 mt-200">
                        <div class="col-10">
                            <img src="images/mobile/highlights.png" alt="" class="lazy-img img-one" />
                        </div>
                    </div>
                </div>
            </div>
            {/* <div class="fancy-feature-seven pt-200 lg-pt-100">
                    <div class="container">
                        <div class="column align-items-center">
                            <div class="col-xl-6 col-lg-5 wow fadeInLeft">
                                <div class="title-style-four text-center text-lg-start">
                                    <h2 class="main-title fw-500 tx-dark m0">Services We <span>Offer.</span></h2>
                                </div>   
                            </div>
                            <div class="col-lg-6  wow fadeInRight">
                                <p class="text-lg text-center text-lg-start md-pt-30 m0">
                                    Our E-Schooling platform comes fully equipped with tools for holistic operation of a school. The platform helps the school management to keep tabs on the daily operations of the school from a central interface. Starting from administrative tools, to multi-
                                    user type portals to have maximum stakeholder involvement, to learning management systems, assessment management systems, reports and analytics models, communication tools and much more which can truly digitise your school
                                    and fulfil the transformative mission.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="container pt-50 lg-pt-10">
                        <div class="row gx-xxl-5">
                            <div class="col-lg-4 col-md-6 wow fadeInUp">
                                <div class="card-style-four text-center position-relative mt-40 xs-mt-20">
                                    <div class="icon rounded-circle m-auto d-flex align-items-center justify-content-center" ><img src="images/lazy.svg" data-src="images/icon/icon_24.svg" alt="" class="lazy-img" /></div>
                                    <h4 class="fw-500 mt-35 mb-25"><a href="#" class="tran3s tx-dark">Administrative Management System</a></h4>
                                    <p class="mb-30">Manage Schools Data and flow in one roof. Create, Update, Delete users with ease.</p>
                                    <a href="#"><img src="images/lazy.svg" data-src="images/icon/icon_05.svg" alt="" class="lazy-img" /></a>
                                </div>
                                
                            </div>
                            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
                                <div class="card-style-four text-center position-relative mt-40 xs-mt-20">
                                    <div class="icon rounded-circle m-auto d-flex align-items-center justify-content-center" ><img src="images/lazy.svg" data-src="images/icon/icon_25.svg" alt="" class="lazy-img" /></div>
                                    <h4 class="fw-500 mt-35 mb-25"><a href="#" class="tran3s tx-dark">Communication Management System</a></h4>
                                    <p class="mb-30">Students, Teachers , Admin can communicate in one chat system for interaction.</p>
                                    <a href="#"><img src="images/lazy.svg" data-src="images/icon/icon_05.svg" alt="" class="lazy-img" /></a>
                                </div>
                                
                            </div>
                            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.4s">
                                <div class="card-style-four text-center position-relative mt-40 xs-mt-20">
                                    <div class="icon rounded-circle m-auto d-flex align-items-center justify-content-center" ><img src="images/lazy.svg" data-src="images/icon/icon_26.svg" alt="" class="lazy-img" /></div>
                                    <h4 class="fw-500 mt-35 mb-25"><a href="#" class="tran3s tx-dark">Learning Management System</a></h4>
                                    <p class="mb-30">Take Advantages of our learning management with content creation , progress chart.</p>
                                    <a href="#"><img src="images/lazy.svg" data-src="images/icon/icon_05.svg" alt="" class="lazy-img" /></a>
                                </div>
                                
                            </div>
                        </div>
                        <div class="row gx-xxl-5">
                            <div class="col-lg-4 col-md-6 wow fadeInUp">
                                <div class="card-style-four text-center position-relative mt-40 xs-mt-20">
                                    <div class="icon rounded-circle m-auto d-flex align-items-center justify-content-center" ><img src="images/lazy.svg" data-src="images/icon/icon_24.svg" alt="" class="lazy-img" /></div>
                                    <h4 class="fw-500 mt-35 mb-25"><a href="#" class="tran3s tx-dark">Assessment Management System</a></h4>
                                    <p class="mb-30">Assesment can be created with ease, Random question generaor and auto Evaluator.</p>
                                    <a href="#"><img src="images/lazy.svg" data-src="images/icon/icon_05.svg" alt="" class="lazy-img" /></a>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.4s">
                                <div class="card-style-four text-center position-relative mt-40 xs-mt-20">
                                    <div class="icon rounded-circle m-auto d-flex align-items-center justify-content-center" ><img src="images/lazy.svg" data-src="images/icon/icon_26.svg" alt="" class="lazy-img" /></div>
                                    <h4 class="fw-500 mt-35 mb-25"><a href="#" class="tran3s tx-dark">Financial Management System</a></h4>
                                    <p class="mb-30">Hassle free management of payroll, fees, expenses, and reimbursement.</p>
                                    <a href="#"><img src="images/lazy.svg" data-src="images/icon/icon_05.svg" alt="" class="lazy-img" /></a>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
                                <div class="card-style-four text-center position-relative mt-40 xs-mt-20">
                                    <div class="icon rounded-circle m-auto d-flex align-items-center justify-content-center"  ><img src="images/lazy.svg" data-src="images/icon/icon_25.svg" alt="" class="lazy-img" /></div>
                                    <h4 class="fw-500 mt-35 mb-25"><a href="#" class="tran3s tx-dark">Reports & Analytics</a></h4>
                                    <p class="mb-30">Get all reports of attendance , learning management and assessment management.</p>
                                    <a href="#"><img src="images/lazy.svg" data-src="images/icon/icon_05.svg" alt="" class="lazy-img" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            <div class="fancy-feature-nine position-relative pt-100 lg-pt-130">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-5">
                            <div class="block-style-three wow fadeInLeft">
                                <div class="title-style-four">

                                    <h2 class="main-title fw-500 tx-dark m0">Notable <span>Highlights</span></h2>
                                </div>

                                <p class="fs-20 pt-45 pe-xxl-5">Youth India E-School aims to make education more accessible than ever before by digitisation of all school operations and bringing all stakeholders together on a single platform. Our ecosystem software empowers educators and
                                    school in seamless delivery of education to the future generation.</p>
                            </div>

                        </div>
                        <div class="col-lg-7 col-md-9 m-auto text-center text-lg-right wow fadeInRight">
                            <div class="screen-container position-relative ms-auto">
                                <img src="images/shape/shape_45.svg" alt="" class="lazy-img shapes bg-round-shape" />
                                <div class="block-content">
                                    <div class="row gx-xxl-5 align-items-center">
                                        <div class="col-sm-6">
                                            <div class="card-style-five wow fadeInDown" data-wow-delay="0.2s">
                                                <div class="icon d-flex align-items-end"><FaUsers size={80} color={"4e76a1"} /></div>
                                                <h4>16,000+ Users</h4>
                                            </div>

                                            <div class="card-style-five xs-mt-10 wow fadeInRight" data-wow-delay="0.2s">
                                                <div class="icon d-flex align-items-end"><FaHandshake size={80} color={"4e76a1"} /></div>
                                                <h4>Multiple Partnerships</h4>
                                            </div>


                                        </div>
                                        <div class="col-sm-6">
                                            <div class="card-style-five xs-mt-10 wow fadeInRight" data-wow-delay="0.2s">
                                                <div class="icon d-flex align-items-end"><FaBrain size={80} color={"4e76a1"} /></div>
                                                <h4>Expert Masterclass</h4>
                                            </div>
                                            <div class="card-style-five wow fadeInUp" data-wow-delay="0.2s">
                                                <div class="icon d-flex align-items-end"><IoIosSchool size={80} color={"4e76a1"} /></div>
                                                <h4>50 Schools</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <USP />
            <div class="fancy-short-banner-sixteen pb-50 wow fadeInUp">
                <div class="container">
                    <div class="bg-wrapper mt-200 pt-65 pb-65 lg-pt-40 lg-pb-40">
                        <div class="row">
                            <div class="col-xl-10 col-md-11 m-auto">
                                <div class="row align-items-center">
                                    <div class="col-lg-6">
                                        <div class="text-wrapper text-center text-lg-start md-pb-30">
                                            <div class="sc-title fs-18 pb-10" style={{ color: "#fff", fontWeight: 500 }}>Have Any Project?</div>
                                            <h2 class="main-title fw-500 text-white m0">Don’t hesitate to send us a message.</h2>
                                        </div>
                                    </div>
                                    <div class="col-lg-5 ms-auto text-center text-lg-end">
                                        <a href="/contactus" class="btn-twentyOne fw-500 tran3s">Get Started Today!</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )

}

export default Highlights;