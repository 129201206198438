import React from "react";

class USP extends React.Component {
    render() {
        return (
            <div class="fancy-feature-eight position-relative mt-225 xl-mt-150 md-mt-50" style={{backgroundColor:"#fff"}}>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-5 col-lg-6 col-md-7 ms-auto order-lg-last">
                            <div class="block-style-three wow fadeInRight">
                                <div class="title-style-four">
                                    <h2 class="main-title fw-500 tx-dark m0">Manage your institution by using our <span>product.</span></h2>
                                </div>
                                
                                <br />
                                <ul class="style-none list-item fs-18">
                                    <li>One Day Installation.</li>
                                    <li>Cutting Edge ERP.</li>
                                    <li>Mobile App Creation.</li>
                                    <li>Zero Failure Chances.</li>
                                    <li>24*7 Customer Care.</li>
                                    <li>Seamless Integration.</li>
                                    <li>Maintenance &amp; Support.</li>
                                </ul>
                                <div class="d-sm-flex align-items-center platform-button-group mt-60 lg-mt-30">
                                    <a href="#" class="d-flex align-items-center ios-button">
                                        <img src="images/icon/apple.svg" alt="" class="lazy-img icon" />
                                        <div>
                                            <span>Download on the</span>
                                            <strong>App store</strong>
                                        </div>
                                    </a>
                                    <a href="https://play.google.com/store/apps/details?id=com.shrikrushana.Eschool" class="d-flex align-items-center windows-button">
                                        <img src="images/icon/playstore.svg" alt="" class="lazy-img icon" />
                                        <div>
                                            <span>Get it on</span>
                                            <strong>Google play</strong>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="illustration-holder">
                    {/* <img src="images/mobile/web.png" alt="" class="lazy-img main-img" /> */}
                    <img src="images/mobile/image3.png" alt="" class="lazy-img main-img" />
                    <img src="images/mobile/image5.png" alt="" class="lazy-img shapes screen-one" style={{height:"380px", width:"auto"}}/>
                    <img src="images/shape/shape_44.svg" alt="" class="lazy-img shapes shape-one" />
                </div>
            </div>
        )

        }
}

export default USP;