import React, { useState, useEffect } from "react";
import "../css/navbar.css"
import Home from "./Home.jsx";
import Footer from "./Footer.jsx";

function NavBarScreen() {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const handleToggle = () => {
        setIsCollapsed(!isCollapsed);
    };
    return (
        <div>
            <header class="theme-main-menu sticky-menu theme-menu-three white-vr">
                <div class="inner-content position-relative">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="logo order-lg-0">
                            <a href="/home" class="d-block align-items-center">
                                <img src="images/logo/icon2.png" alt="" width="65" />
                            </a>
                            <a href="/home" class=" align-items-center">
                                <span style={{ fontWeight: 600, fontSize: 14, fontFamily: "Gordita", color: "#FFF" }}>
                                    Youth India<br /> E-School
                                </span>
                            </a>
                        </div>

                        <div class="right-widget d-flex align-items-center ms-auto ms-lg-0 order-lg-3">
                            <a href="https://sundergarh.youthindiaeschool.com/" class="login-btn-two fw-500 d-flex align-items-center me-3">
                                <img src="images/icon/icon_20.svg" alt="" class="me-2" />
                                <span>Log in</span>
                            </a>
                            <a href="https://calendly.com/pingaleshrikrushana/30min" class="signup-btn-one tran3s position-relative ps-3 d-none d-lg-block">Book a <span class="fw-500">demo</span> with us</a>
                        </div>

                        <nav class="navbar navbar-expand-lg order-lg-2">
                            <button
                                className="navbar-toggler"
                                type="button"
                                onClick={handleToggle}
                            >
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className={`collapse navbar-collapse ${isCollapsed ? '' : 'show'}`}>
                                <ul class="navbar-nav">
                                    <li class="d-block d-lg-none">
                                        <div class="logo">
                                            <a href="/home" class="d-block align-items-center">
                                                <img src="images/logo/icon2.png" alt="" width="65" />
                                            </a>
                                            <a href="/home" class=" align-items-center">
                                                <span style={{ fontWeight: 600, fontSize: 14, fontFamily: "Gordita", color: "#FFF" }}>
                                                    Youth India<br /> E-School
                                                </span>
                                            </a>
                                        </div>
                                    </li>
                                    <li class="nav-item dropdown mega-dropdown">
                                        <a class="nav-link" href="/home" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="true" style={{ color: "white" }}>Home</a>
                                    </li>
                                    <li class="nav-item dropdown mega-dropdown-md">
                                        <a class="nav-link " href="/aboutus" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" style={{ color: "white" }}>About</a>

                                    </li>
                                    <li class="nav-item dropdown">
                                        <a class="nav-link " href="/services" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" style={{ color: "white" }}>Services</a>

                                    </li>
                                    <li class="nav-item dropdown">
                                        <a class="nav-link" href="/highlights" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" style={{ color: "white" }}>Features</a>
                                    </li>
                                    <li class="nav-item dropdown">
                                        <a class="nav-link" href="/contactus" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" style={{ color: "white" }}>Contact</a>
                                    </li>
                                </ul>
                                <div class="mobile-content d-block d-lg-none">
                                    <div class="d-flex flex-column align-items-center justify-content-center mt-70">
                                        <a href="https://calendly.com/pingaleshrikrushana/30min" class="signup-btn-one tran3s position-relative">Book a <span class="fw-500">demo </span>with us</a>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>

            </header>
            <Home />
            <div style={{ marginTop: 150 }}>
                <Footer />
            </div>

        </div>
    )
}

export default NavBarScreen;