import React, { useState, useEffect } from "react";
import { Stack, Carousel } from 'react-bootstrap';

function Footer() {
	const [isMobileView, setIsMobileView] = useState(false);
	useEffect(() => {
		const handleResize = () => {
			setIsMobileView(window.innerWidth <= 821); // Adjust the breakpoint as needed
		};

		window.addEventListener('resize', handleResize);
		handleResize();

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);
	return (
		<div class="footer-style-six theme-basic-footer position-relative">
			<div class="container">
				<div class="inner-wrapper">
					<div class="row justify-content-between">
						{
							isMobileView === false ?
								<Stack direction="vertical">
									<div class="col-lg-2 footer-intro mb-40">
										<div class="logo">
											<a href="/home"><img src="images/logo/icon2.png" alt="" width="75" /></a>
											<a href="/home">
												<span style={{ fontWeight: 600, fontSize: 14, fontFamily: "Gordita", color: "white" }}>
													Youth India<br /> E-School
												</span>
											</a>
										</div>
									</div>
									<Stack direction="horizontal">
										<div class="col-lg-2 col-md-3 col-sm-6 mb-30 ms-auto">
											<h5 class="footer-title tx-dark fw-500" style={{ color: "#edf6f9" }}>Links</h5>
											<ul class="footer-nav-link style-none">
												<li><a href="/home" style={{ color: "white" }}>Home</a></li>
												<li><a href="/aboutus" style={{ color: "white" }}>About us</a></li>
												<li><a href="/services" style={{ color: "white" }}>Service</a></li>
												<li><a href="/highlights" style={{ color: "white" }}>Features</a></li>
												<li><a href="/privacy" style={{ color: "white" }}>Privacy Policy</a></li>
											</ul>
										</div>
										<div class="col-md-3 col-sm-6 mb-30 ms-auto">
											<h5 class="footer-title tx-dark fw-500" style={{ color: "#edf6f9" }}>Our Address</h5>
											<p class="fs-17" style={{ color: "#edf6f9" }}> 2nd Floor, CM-1, VSS Nagar, <br />Bhubaneswar, Odisha</p>
											<a href="mailto:info@youthindiaeschool.com" class="email tran3s fs-17" style={{ color: "#edf6f9" }}>info@youthindiaeschool.com</a> <br />
											<a href="tel:+917848939373" class="mobile tran3s fs-20 mt-20 mb-30" style={{ color: "#edf6f9" }}>+91-7848939373</a> <br />
											<a href="tel:+919582961770" class="mobile tran3s fs-20 mt-20 mb-30" style={{ color: "#edf6f9" }}>+91-9582961770</a>
											<ul class="d-flex social-icon style-none mt-10 md-mt-10">
												<li><a href="https://www.linkedin.com/company/youth-india-e-school" style={{ color: "#edf6f9" }}><i class="fab fa-linkedin-in"></i></a></li>
												<li><a href="https://twitter.com/YIEschool?s=09" style={{ color: "#edf6f9" }}><i class="fab fa-twitter"></i></a></li>
												<li><a href="https://www.instagram.com/youthindiaeschool/" style={{ color: "#edf6f9" }}><i class="fab fa-instagram"></i></a></li>
												<li><a href="https://www.youtube.com/@youthindiae-school5362/videos" style={{ color: "#edf6f9" }}><i class="fab fa-youtube"></i></a></li>
											</ul>
										</div>
									</Stack>
								</Stack>
								:
								<div>
									<Stack>
										<div class="col-lg-2 footer-intro mb-40">
											<div class="logo">
												<a href="/home"><img src="images/logo/icon2.png" alt="" width="75" /></a>
												<a href="/home">
													<span style={{ fontWeight: 600, fontSize: 14, fontFamily: "Gordita", color: "white" }}>
														Youth India<br /> E-School
													</span>
												</a>
											</div>
										</div>
										<Stack direction="horizontal">
											<div class="col-lg-2 col-md-3 col-sm-6 mb-30">
												<h5 class="footer-title tx-dark fw-500" style={{ color: "#edf6f9" }}>Links</h5>
												<ul class="footer-nav-link style-none">
													<li><a href="/home" style={{ color: "white" }}>Home</a></li>
													<li><a href="/aboutus" style={{ color: "white" }}>About us</a></li>
													<li><a href="/services" style={{ color: "white" }}>Service</a></li>
													<li><a href="/highlights" style={{ color: "white" }}>Features</a></li>
													<li><a href="/privacy" style={{ color: "white" }}>Privacy Policy</a></li>
												</ul>
											</div>
											<div class="col-md-3 col-sm-6 mb-30 ms-auto">
												<h5 class="footer-title tx-dark fw-500" style={{ color: "#edf6f9" }}>Our Address</h5>
												<p class="fs-17" style={{ color: "#edf6f9" }}>2nd Floor, CM-1, VSS Nagar, <br />Bhubaneswar, Odisha</p>
												<a href="mailto:info@youthindiaeschool.com" class="email tran3s fs-17" style={{ color: "#edf6f9" }}>info@youthindiaeschool.com</a> <br />
												<a href="tel:+917848939373" class="mobile tran3s fs-20 mt-20 mb-30" style={{ color: "#edf6f9" }}>+91-7848939373</a> <br />
												<a href="tel:+919582961770" class="mobile tran3s fs-20 mt-20 mb-30" style={{ color: "#edf6f9" }}>+91-9582961770</a>
												<ul class="d-flex social-icon style-none mt-5">
													<li><a href="https://www.linkedin.com/company/youth-india-e-school" style={{ color: "#edf6f9" }}><i class="fab fa-linkedin-in"></i></a></li>
													<li><a href="https://twitter.com/YIEschool?s=09" style={{ color: "#edf6f9" }}><i class="fab fa-twitter"></i></a></li>
													<li><a href="https://www.instagram.com/youthindiaeschool/" style={{ color: "#edf6f9" }}><i class="fab fa-instagram"></i></a></li>
													<li><a href="https://www.youtube.com/@youthindiae-school5362/videos" style={{ color: "#edf6f9" }}><i class="fab fa-youtube"></i></a></li>
												</ul>
											</div>
										</Stack>
									</Stack>
								</div>
						}
					</div>
					<div class="bottom-footer">
						<p class="copyright text-center m0" style={{ color: "#edf6f9" }}>Copyright @ 2023 Youth India E-School. All Rights Reserved.</p>
					</div>
				</div>
			</div>
		</div>
	)

}

export default Footer;