import React, { useState, useEffect } from "react";
import Footer from "./Footer.jsx"
import { Stack, Carousel } from 'react-bootstrap';
import Testimonials from "./Testimonials.jsx";
import Marquee from "react-fast-marquee";
import $ from 'jquery';
import "jquery-ui-dist/jquery-ui"
function AboutUs() {
    // const [page, setPage] = useState(0);
    // useEffect(() => {
    //     (function ($) {
    //         "use strict";


    //         // --------------------- Add .active class to current navigation based on URL
    //         var pgurl = window.location.href.substr(window.location.href.lastIndexOf("/") + 1);
    //         $(".navbar-nav > li  a").each(function () {
    //             if ($(this).attr("href") == pgurl || $(this).attr("href") == '')
    //                 $(this).addClass("active");
    //             $(this).parent("li").addClass("active");
    //         })

    //         // ----------------------------- Lazy Load
    //         // if ($(".lazy-img").length) {
    //         //     $('.lazy-img').azy({
    //         //         effect: 'fadeIn',
    //         //         threshold: 300
    //         //     });
    //         // }


    //         // ----------------------------- Counter Function
    //         var timer = $('.counter');
    //         if (timer.length) {
    //             $('.counter').counterUp({
    //                 delay: 10,
    //                 time: 1200,
    //             });
    //         }

    //         // ------------------------ Navigation Scroll
    //         $(window).on('scroll', function () {
    //             var sticky = $('.sticky-menu'),
    //                 scroll = $(window).scrollTop();
    //             if (scroll >= 100) sticky.addClass('fixed');
    //             else sticky.removeClass('fixed');

    //         });

    //         // -------------------- From Bottom to Top Button
    //         $(window).on('scroll', function () {
    //             if ($(this).scrollTop() > 200) {
    //                 $('.scroll-top').fadeIn();
    //             } else {
    //                 $('.scroll-top').fadeOut();
    //             }
    //         });

    //         //---------------------- Click event to scroll to top
    //         $('.scroll-top').on('click', function () {
    //             $('html, body').animate({ scrollTop: 0 });
    //             return false;
    //         });

    //         // -------------------- Remove Placeholder When Focus Or Click
    //         $("input,textarea").each(function () {
    //             $(this).data('holder', $(this).attr('placeholder'));
    //             $(this).on('focusin', function () {
    //                 $(this).attr('placeholder', '');
    //             });
    //             $(this).on('focusout', function () {
    //                 $(this).attr('placeholder', $(this).data('holder'));
    //             });
    //         });


    //         // ----------------------------- Select Function
    //         if ($(".nice-select").length) {
    //             $('.nice-select').niceSelect();
    //         }

    //         // ------------------------ Feedback Slider One
    //         if ($(".feedback_slider_one").length) {
    //             $('.feedback_slider_one').slick({
    //                 dots: false,
    //                 arrows: true,
    //                 prevArrow: $('.prev_f1'),
    //                 nextArrow: $('.next_f1'),
    //                 centerPadding: '0px',
    //                 slidesToShow: 3,
    //                 slidesToScroll: 1,
    //                 autoplay: true,
    //                 centerMode: true,
    //                 autoplaySpeed: 3000,
    //                 responsive: [
    //                     {
    //                         breakpoint: 992,
    //                         settings: {
    //                             slidesToShow: 1
    //                         }
    //                     }
    //                 ]
    //             });
    //         }


    //         // ------------------------ Feedback Slider Two
    //         if ($(".feedback_slider_two").length) {
    //             $('.feedback_slider_two').slick({
    //                 dots: false,
    //                 arrows: true,
    //                 prevArrow: $('.prev_f2'),
    //                 nextArrow: $('.next_f2'),
    //                 centerPadding: '0px',
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1,
    //                 autoplay: true,
    //                 autoplaySpeed: 3000,
    //             });
    //         }

    //         // ------------------------ Feedback Slider Three
    //         if ($(".feedback_slider_three").length) {
    //             $('.feedback_slider_three').slick({
    //                 dots: true,
    //                 arrows: false,
    //                 centerPadding: '0px',
    //                 slidesToShow: 4,
    //                 slidesToScroll: 1,
    //                 autoplay: true,
    //                 autoplaySpeed: 3000,
    //                 responsive: [
    //                     {
    //                         breakpoint: 1200,
    //                         settings: {
    //                             slidesToShow: 3
    //                         }
    //                     },
    //                     {
    //                         breakpoint: 992,
    //                         settings: {
    //                             slidesToShow: 2
    //                         }
    //                     },
    //                     {
    //                         breakpoint: 768,
    //                         settings: {
    //                             slidesToShow: 1
    //                         }
    //                     }
    //                 ]
    //             });
    //         }




    //         // ------------------------ Feedback Slider Four
    //         if ($(".feedback_slider_four").length) {
    //             $('.feedback_slider_four').slick({
    //                 centerPadding: '0px',
    //                 arrows: false,
    //                 dots: true,
    //                 slidesToShow: 3,
    //                 autoplay: true,
    //                 autoplaySpeed: 3000,
    //                 responsive: [
    //                     {
    //                         breakpoint: 1000,
    //                         settings: {
    //                             slidesToShow: 2
    //                         }
    //                     },
    //                     {
    //                         breakpoint: 768,
    //                         settings: {
    //                             slidesToShow: 1
    //                         }
    //                     }
    //                 ]
    //             });
    //         }


    //         // ------------------------ Feedback Slider Five
    //         if ($(".feedback_slider_five").length) {
    //             $('.feedback_slider_five').slick({
    //                 centerPadding: '0px',
    //                 arrows: true,
    //                 prevArrow: $('.prev_f2'),
    //                 nextArrow: $('.next_f2'),
    //                 dots: false,
    //                 slidesToShow: 4,
    //                 autoplay: true,
    //                 autoplaySpeed: 3000,
    //                 responsive: [
    //                     {
    //                         breakpoint: 992,
    //                         settings: {
    //                             slidesToShow: 3
    //                         }
    //                     },
    //                     {
    //                         breakpoint: 768,
    //                         settings: {
    //                             slidesToShow: 1
    //                         }
    //                     }
    //                 ]
    //             });
    //         }

    //         // ------------------------ Feedback Slider Six
    //         if ($(".feedback_slider_six").length) {
    //             $('.feedback_slider_six').slick({
    //                 dots: false,
    //                 arrows: true,
    //                 prevArrow: $('.prev_s2'),
    //                 nextArrow: $('.next_s2'),
    //                 centerPadding: '0px',
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1,
    //                 autoplay: true,
    //                 autoplaySpeed: 3000,
    //             });
    //         }



    //         // ------------------------ Feedback Slider Seven
    //         if ($(".feedback_slider_seven").length) {
    //             $('.feedback_slider_seven').slick({
    //                 centerPadding: '0px',
    //                 arrows: false,
    //                 dots: true,
    //                 slidesToShow: 3,
    //                 centerMode: true,
    //                 autoplay: true,
    //                 autoplaySpeed: 3000,
    //                 responsive: [
    //                     {
    //                         breakpoint: 768,
    //                         settings: {
    //                             slidesToShow: 1
    //                         }
    //                     }
    //                 ]
    //             });
    //         }

    //         // ------------------------ Feedback Slider Nine
    //         if ($(".feedback_slider_nine").length) {
    //             $('.feedback_slider_nine').slick({
    //                 centerPadding: '0px',
    //                 arrows: false,
    //                 dots: true,
    //                 slidesToShow: 2,
    //                 autoplay: true,
    //                 autoplaySpeed: 3000,
    //                 responsive: [
    //                     {
    //                         breakpoint: 768,
    //                         settings: {
    //                             slidesToShow: 1
    //                         }
    //                     }
    //                 ]
    //             });
    //         }

    //         // ------------------------ Feedback Slider Ten
    //         if ($(".feedback_slider_ten").length) {
    //             $('.feedback_slider_ten').slick({
    //                 centerPadding: '0px',
    //                 arrows: true,
    //                 prevArrow: $('.prev_f5'),
    //                 nextArrow: $('.next_f5'),
    //                 dots: false,
    //                 slidesToShow: 3,
    //                 autoplay: true,
    //                 autoplaySpeed: 3500,
    //                 responsive: [
    //                     {
    //                         breakpoint: 1200,
    //                         settings: {
    //                             slidesToShow: 2
    //                         }
    //                     },
    //                     {
    //                         breakpoint: 768,
    //                         settings: {
    //                             slidesToShow: 1
    //                         }
    //                     }
    //                 ]
    //             });
    //         }

    //         // ------------------------ Feedback Slider Eleven
    //         if ($(".feedback_slider_eleven").length) {
    //             $('.feedback_slider_eleven').slick({
    //                 centerPadding: '0px',
    //                 arrows: false,
    //                 dots: true,
    //                 slidesToShow: 1,
    //                 autoplay: true,
    //                 autoplaySpeed: 3500,
    //             });
    //         }

    //         // ------------------------ Partner Slider One
    //         if ($(".partner_slider_one").length) {
    //             $('.partner_slider_one').slick({
    //                 centerPadding: '0px',
    //                 arrows: false,
    //                 dots: false,
    //                 slidesToShow: 5,
    //                 autoplay: true,
    //                 autoplaySpeed: 3000,
    //                 responsive: [
    //                     {
    //                         breakpoint: 992,
    //                         settings: {
    //                             slidesToShow: 4
    //                         }
    //                     },
    //                     {
    //                         breakpoint: 768,
    //                         settings: {
    //                             slidesToShow: 3
    //                         }
    //                     },
    //                     {
    //                         breakpoint: 576,
    //                         settings: {
    //                             slidesToShow: 2
    //                         }
    //                     }
    //                 ]
    //             });
    //         }


    //         // ------------------------ Course Slider One
    //         if ($(".course_slider_one").length) {
    //             $('.course_slider_one').slick({
    //                 centerPadding: '0px',
    //                 arrows: true,
    //                 prevArrow: $('.prev_cs1'),
    //                 nextArrow: $('.next_cs1'),
    //                 dots: false,
    //                 slidesToShow: 4,
    //                 autoplay: true,
    //                 autoplaySpeed: 3000,
    //                 responsive: [
    //                     {
    //                         breakpoint: 992,
    //                         settings: {
    //                             slidesToShow: 2
    //                         }
    //                     },
    //                     {
    //                         breakpoint: 576,
    //                         settings: {
    //                             slidesToShow: 1
    //                         }
    //                     }
    //                 ]
    //             });
    //         }


    //         // ----------------------------- Animated Round Progressbar
    //         if ($(".circle_percent").length) {
    //             $(".circle_percent").each(function () {
    //                 var $this = $(this),
    //                     $dataV = $this.data("percent"),
    //                     $dataDeg = $dataV * 3.6,
    //                     $round = $this.find(".round_per");
    //                 $round.css("transform", "rotate(" + parseInt($dataDeg + 180) + "deg)");
    //                 $this.append('<div class="circle_inbox"><span class="percent_text"></span></div>');
    //                 $this.prop('Counter', 0).animate({ Counter: $dataV },
    //                     {
    //                         duration: 2000,
    //                         easing: 'swing',
    //                         step: function (now) {
    //                             $this.find(".percent_text").text(Math.ceil(now) + "%");
    //                         }
    //                     });
    //                 if ($dataV >= 51) {
    //                     $round.css("transform", "rotate(" + 360 + "deg)");
    //                     setTimeout(function () {
    //                         $this.addClass("percent_more");
    //                     }, 1000);
    //                     setTimeout(function () {
    //                         $round.css("transform", "rotate(" + parseInt($dataDeg + 180) + "deg)");
    //                     }, 1000);
    //                 }
    //             });
    //         };

    //         // ------------------------ Password Toggler
    //         if ($(".user-data-form").length) {
    //             $(".passVicon").on('click', function () {
    //                 $(".passVicon").toggleClass("eye-slash");
    //                 var input = $(".pass_log_id");
    //                 if (input.attr("type") === "password") {
    //                     input.attr("type", "text");
    //                 } else {
    //                     input.attr("type", "password");
    //                 }

    //             });
    //         }


    //         // ----------------------- Closes responsive menu when a scroll trigger link is clicked
    //         $('#one-page-nav .nav-link').on('click', function () {
    //             $('.navbar-collapse').removeClass('show');
    //             $('.navbar-toggler').attr("aria-expanded", "false");
    //         })



    //         // --------------------------------- Contact Form
    //         // init the validator
    //         // validator files are included in the download package
    //         // otherwise download from http://1000hz.github.io/bootstrap-validator

    //         if ($("#contact-form").length) {
    //             $('#contact-form').validator();
    //             // when the form is submitted
    //             $('#contact-form').on('submit', function (e) {

    //                 // if the validator does not prevent form submit
    //                 if (!e.isDefaultPrevented()) {
    //                     var url = "inc/contact.php";

    //                     // POST values in the background the the script URL
    //                     $.ajax({
    //                         type: "POST",
    //                         url: url,
    //                         data: $(this).serialize(),
    //                         success: function (data) {
    //                             // data = JSON object that contact.php returns

    //                             // we recieve the type of the message: success x danger and apply it to the
    //                             var messageAlert = 'alert-' + data.type;
    //                             var messageText = data.message;

    //                             // let's compose Bootstrap alert box HTML
    //                             var alertBox = '<div class="alert ' + messageAlert + ' alert-dismissable"><button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>' + messageText + '</div>';

    //                             // If we have messageAlert and messageText
    //                             if (messageAlert && messageText) {
    //                                 // inject the alert to .messages div in our form
    //                                 $('#contact-form').find('.messages').html(alertBox);
    //                                 // empty the form
    //                                 $('#contact-form')[0].reset();
    //                             }
    //                         }
    //                     });
    //                     return false;
    //                 }
    //             });
    //         }


    //         $(window).on('load', function () { // makes sure the whole site is loaded

    //             // -------------------- Site Preloader
    //             $('#ctn-preloader').fadeOut(); // will first fade out the loading animation
    //             $('#preloader').delay(350).fadeOut('slow'); // will fade out the white DIV that covers the website.
    //             $('body').delay(350).css({ 'overflow': 'visible' });



    //             // ------------------------------- Scroll Animation
    //             // var wow = new wow(
    //             //     {
    //             //         boxClass: 'wow',      // animated element css class (default is wow)
    //             //         animateClass: 'animated', // animation css class (default is animated)
    //             //         offset: 0,          // distance to the element when triggering the animation (default is 0)
    //             //         mobile: true,       // trigger animations on mobile devices (default is true)
    //             //         live: true,       // act on asynchronously loaded content (default is true)
    //             //     }
    //             // );
    //             // wow.init();

    //             // ------------------------------------- Fancybox
    //             var fancy = $(".fancybox");
    //             if (fancy.length) {
    //                 fancy.fancybox({
    //                     arrows: true,
    //                     buttons: [
    //                         "zoom",
    //                         //"share",
    //                         "slideShow",
    //                         //"fullScreen",
    //                         //"download",
    //                         "thumbs",
    //                         "close"
    //                     ],
    //                     animationEffect: "zoom-in-out",
    //                     transitionEffect: "zoom-in-out",
    //                 });
    //             }



    //             // ----------------------------- isotop gallery
    //             if ($("#isotop-gallery-wrapper").length) {
    //                 var $grid = $('#isotop-gallery-wrapper').isotope({
    //                     // options
    //                     itemSelector: '.isotop-item',
    //                     percentPosition: true,
    //                     masonry: {
    //                         // use element for option
    //                         columnWidth: '.grid-sizer'
    //                     }

    //                 });

    //                 // filter items on button click
    //                 $('.isotop-menu-wrapper').on('click', 'li', function () {
    //                     var filterValue = $(this).attr('data-filter');
    //                     $grid.isotope({ filter: filterValue });
    //                 });

    //                 // change is-checked class on buttons
    //                 $('.isotop-menu-wrapper').each(function (i, buttonGroup) {
    //                     var $buttonGroup = $(buttonGroup);
    //                     $buttonGroup.on('click', 'li', function () {
    //                         $buttonGroup.find('.is-checked').removeClass('is-checked');
    //                         $(this).addClass('is-checked');
    //                     });
    //                 });
    //             }


    //         });  //End On Load Function


    //     })($);
    // }, [page])
    const [isCollapsed, setIsCollapsed] = useState(true);

    const handleToggle = () => {
        setIsCollapsed(!isCollapsed);
    };
    const [isMobileView, setIsMobileView] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 821); // Adjust the breakpoint as needed
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <div>
            <header class="theme-main-menu sticky-menu theme-menu-three white-vr">
                <div class="inner-content position-relative">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="logo order-lg-0">
                            <a href="/home" class="d-block align-items-center">
                                <img src="images/logo/icon2.png" alt="" width="55" />
                            </a>
                            <a href="/home" class=" align-items-center">
                                <span style={{ fontWeight: 600, fontSize: 14, fontFamily: "Gordita", color: "#FFF" }}>
                                    Youth India<br /> E-School
                                </span>
                            </a>
                        </div>

                        <div class="right-widget d-flex align-items-center ms-auto ms-lg-0 order-lg-3">
                            <a href="signin.html" class="login-btn-two fw-500 d-flex align-items-center me-3">
                                <img src="images/icon/icon_20.svg" alt="" class="me-2" />
                                <span>Log in</span>
                            </a>
                            <a href="https://calendly.com/pingaleshrikrushana/30min" class="signup-btn-one tran3s position-relative ps-3 d-none d-lg-block">Book a <span class="fw-500">demo</span> with us</a>
                        </div>

                        <nav class="navbar navbar-expand-lg order-lg-2">
                            <button
                                className="navbar-toggler"
                                type="button"
                                onClick={handleToggle}
                            >
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className={`collapse navbar-collapse ${isCollapsed ? '' : 'show'}`}>
                                <ul class="navbar-nav">
                                    <li class="d-block d-lg-none">
                                        <div class="logo">
                                            <a href="/home" class="d-block align-items-center">
                                                <img src="images/logo/icon2.png" alt="" width="55" />
                                            </a>
                                            <a href="/home" class=" align-items-center">
                                                <span style={{ fontWeight: 600, fontSize: 14, fontFamily: "Gordita", color: "#FFF" }}>
                                                    Youth India<br /> E-School
                                                </span>
                                            </a>
                                        </div>
                                    </li>
                                    <li class="nav-item active dropdown mega-dropdown">
                                        <a class="nav-link " href="/home" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="flase" style={{ color: "white" }}>Home</a>
                                    </li>
                                    <li class="nav-item dropdown mega-dropdown-md">
                                        <a class="nav-link " href="/aboutus" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" style={{ color: "white" }}>About</a>

                                    </li>
                                    <li class="nav-item dropdown mega-dropdown-md">
                                        <a class="nav-link " href="/services" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" style={{ color: "white" }}>Services</a>

                                    </li>
                                    <li class="nav-item dropdown mega-dropdown-md">
                                        <a class="nav-link " href="/highlights" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" style={{ color: "white" }}>Features</a>
                                    </li>
                                    <li class="nav-item dropdown mega-dropdown-md">
                                        <a class="nav-link " href="/contactus" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" style={{ color: "white" }}>Contact</a>
                                    </li>
                                </ul>
                                <div class="mobile-content d-block d-lg-none">
                                    <div class="d-flex flex-column align-items-center justify-content-center mt-70">
                                        <a href="https://calendly.com/pingaleshrikrushana/30min" class="signup-btn-one tran3s position-relative">Book a <span class="fw-500">demo </span>with us</a>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </header>
            <div class="hero-banner-three position-relative pt-200 md-pt-150">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 wow fadeInLeft" style={{ animationName: 'fadeInLeft' }}>
                            <h1 class="hero-heading fw-bold mt-45 mb-40 md-mt-20">About Us.</h1>
                            {
                                isMobileView === false ?
                                    <p class="text-lg mb-50 lg-mb-30 pe-xxl-4">Welcome to Youth India E-School, where education knows no boundaries!</p>
                                    :
                                    <p class="text-lg mb-50 lg-mb-30 pe-xxl-4" style={{ color: 'white' }}>Welcome to Youth India E-School, where education knows no boundaries!</p>
                            }
                        </div>
                    </div>
                </div>
                <div class="mobile-screen wow fadeInRight">
                    <div class="justify-content-center align-items-center mb-50 mt-200">
                        <div class="col-10">
                            <img src="images/mobile/aboutUs.png" alt="" class="lazy-img img-one" />
                        </div>
                    </div>
                </div>
            </div>

            {/* our initiative section */}

            <div class="partner-section-two position-relative mt-300 mb-50 md-mt-120 md-mb-120">
                <div class="container">
                    <div class="column align-items-center">
                        <div class="col-xl-6 col-lg-5 wow fadeInLeft">
                            <div class="title-style-four text-center text-lg-start">
                                <h2 class="main-title fw-500 tx-dark m0">Our <span>Initiative.</span></h2>
                            </div>
                        </div>
                        <br />
                        <div class="col-lg-6  wow fadeInRight">
                            <p class="text-lg text-center text-lg-start md-pt-30 m0">
                                Youth India E-School is an initiative by the Youth India foundation aimed at empowering the youths from all the sections of Indian society and providing an easily accessible and subsidized, quality education through our E-School platform. It identified the gap between direct consumer positioned learning apps and administrative tools created for schools and has created an ecosystem software which takes every single school as a unit and aims to digitise all the processes conducively.
                            </p>
                            <br />
                            {/* <p class="text-lg text-center text-lg-start md-pt-30 m0">
                                As a socio-enterprise, we understand that education is not only a means to gain knowledge but also a tool for social mobility and empowerment. We believe that everyone, regardless of their economic background, deserves equal opportunities to fulfill their potential and contribute to society.
                            </p>
                            <br /> */}
                            <p class="text-lg text-center text-lg-start md-pt-30 m0">
                                We at Youth India E-School aim to make education more accessible than ever before by providing everyone the same quality of learning tools as the rest of the world.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="logo-wrapper d-flex flex-wrap justify-content-center align-items-center pl-200 md-p20">
                    <img style={{ width: "510px", borderRadius: "25px" }} src="images/carosel/5.jpg" alt="" class="pic lazy-img" />
                    <img style={{ marginTop: "50px", width: "510px", borderRadius: "25px" }} src="images/carosel/18.jpg" alt="" class="pic lazy-img" />
                </div>
            </div>

            {/* link with foundation section */}

            <div class="partner-section-two position-relative mt-300 mb-50 md-mt-120 md-mb-0">
                <div class="container">
                    <div class="column align-items-center">
                        <div class="col-xl-6 col-lg-5 wow fadeInLeft">
                            <div class="title-style-four text-center text-lg-start">
                                <h2 class="main-title fw-500 tx-dark m0">Youth India <span>Foundation.</span></h2>
                            </div>
                        </div>
                        <br />
                        <div class="col-lg-6  wow fadeInRight">
                            <p class="text-lg text-center text-lg-start md-pt-30 m0">
                                Youth India Foundation (YIF) is a social entrepreneurship platform where the youth from all over the nation unites to implement their skills and knowledge for a good cause by working for social entrepreneurship models. Youth India Foundation focuses on creating an ecosystem of social startups by providing a platform where the youth of today can enhance their skills through implementation of initiatives. It is India’s largest youth driven social entrepreneurship platform as it is operating as 50+ college societies across 10+ states PAN India. We try to bring a change for the better tomorrow. It is a place where we endeavour to meet our expectations of an "Incredible India".
                            </p>
                            <br />
                            <p class="text-lg text-center text-lg-start md-pt-30 m0">
                                The objective of the foundation has always been to uplift the workforce and provide permanent solutions to society. Youth India Foundation envisages to create a group of social enterprises which are focusing equally on social impact and revenue generation for scalability, sustainability and must be a necessity in the market. E School is one such social enterprise. 
                            </p>
                            {/* <br />
                            <p class="text-lg text-center text-lg-start md-pt-30 m0">
                                As a patron organisation Youth India Foundation plays a key role in E School’s day to day operations by deploying a highly decorated panel of advisors, more than 7000 members actively working for the foundation, its network guideline and principles which consistently enhances the project implementation, problem resolution, product development and market traction of E School’s products and services while keeping a strong control on the moral responsibility upon which E-School has embarked to serve the society while being a successful venture. 
                            </p> */}
                        </div>
                    </div>
                </div>
                <div class="logo-wrapper d-flex flex-wrap justify-content-center align-items-center pl-200 md-p20">
                    <img style={{ width: "510px", borderRadius: "25px" }} src="images/carosel/youth1.jpg" alt="" class="pic lazy-img" />
                    <img style={{ marginTop: "50px", width: "510px", borderRadius: "25px" }} src="images/carosel/youth2.jpg" alt="" class="pic lazy-img" />
                </div>
            </div>

            {/* Separate section for link with foundation */}

            <div class="fancy-feature-seven ">
                <div class="container lg-pt-10">
                    <div class="row gx-xxl-5">
                        <div class="col-lg-12 col-md-6 wow fadeInUp">
                            <div class="card-style-four text-center position-relative mt-40 xs-mt-20" style={{backgroundColor: "#F0F8FF", borderRadius:"25px", padding:"30px"}}>
                                <p class="mb-10 md-m0" style={{ marginRight: "30px", marginLeft: "30px", textAlign: "justify", fontSize:"20px" }}>
                                    As a patron organisation Youth India Foundation plays a key role in E-School’s day to day operations by deploying a highly decorated panel of advisors, more than 7000 members actively working for the foundation, its network guideline and principles which consistently enhances the project implementation, problem resolution, product development and market traction of E School’s products and services while keeping a strong control on the moral responsibility upon which E-School has embarked to serve the society while being a successful venture. 
                                </p>

                                {/* <a href="#"><img src="images/team/th.jpg" data-src="images/icon/icon_05.svg" alt="" class="lazy-img" /></a> */}
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            {/* Inagurated by section */}

            <div class="fancy-feature-seven pt-200 lg-pt-100">
                <div class="container">
                    <div class="column align-items-center">
                        <div class="col-xl-6 col-lg-5 wow fadeInLeft">
                            <div class="title-style-four text-center text-lg-start">
                                <h2 class="main-title fw-500 tx-dark m0">Inaugurated <span>by..</span></h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container pt-50 lg-pt-10">
                    <div class="row gx-xxl-5" style={{alignItems:"center"}}>
                        <div class="col-lg-4 col-md-6 wow fadeInUp">
                            <div class="card-style-four text-center position-relative mt-40 xs-mt-20">
                                <div class="rounded-circle m-auto d-flex align-items-center justify-content-center" ><img style={{ borderRadius: "400px", width: "250px" }} src="images/team/bijoy.jpg" alt="" /></div>
                                <h4 class="fw-500 mt-35 mb-25"><a href="#" class="tran3s tx-dark">Late Dr. Bijoy Kumar Sahoo</a></h4>
                                <p class="mb-30">Former Advisor of OAVS and Founder-Chairman of Sai International</p>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-6 wow fadeInUp">
                            <div class="card-style-four text-center position-relative mt-40 xs-mt-20">
                                {
                                    isMobileView === false ?
                                        <iframe 
                                            width="100%" 
                                            height="450"
                                            src="https://www.youtube.com/embed/T7551ggiMzk?autoplay=1&mute=0&start=2775&end=2826"
                                            style={{borderRadius: "25px"}}
                                        />
                                    :
                                        <iframe
                                            width="100%"
                                            height="250"
                                            src="https://www.youtube.com/embed/T7551ggiMzk?autoplay=1&mute=0&start=2775&end=2826"
                                            style={{ borderRadius: "25px" }}
                                        />

                                }
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* advisors section */}

         

            {/* Founder's Message */}

            <div class="fancy-feature-seven pt-200 lg-pt-100">
                <div class="container">
                    <div class="column align-items-center">
                        <div class="col-xl-6 col-lg-5 wow fadeInLeft">
                            <div class="title-style-four text-center text-lg-start">
                                <h2 class="main-title fw-500 tx-dark m0">Founder's <span>Message.</span></h2>
                            </div>
                        </div>
                        <br />
                    </div>
                </div>
                <div class="container pt-50 lg-pt-10">
                    <div class="row gx-xxl-5">
                        <div class="col-lg-12 col-md-6 wow fadeInUp">
                            <div class="card-style-four text-center position-relative mt-40 xs-mt-20">
                                <div class="rounded-circle m-auto d-flex align-items-center justify-content-center" ><img style={{ borderRadius: "400px", width: "250px" }} src="images/team/founder.jpg" alt="" /></div>
                                <h4 class="fw-500 mt-35 mb-25"><a href="#" class="tran3s tx-dark">Subhankar Nanda</a></h4>
                                <p class="mb-30">Chairman &amp; Managing Director<br /> Youth India E-School</p>
                                <p class="mb-10 md-m0" style={{ marginRight: "30px", marginLeft: "30px", textAlign: "justify" }}>
                                    E-School is our vision of making education in India, accessible, affordable, and deliverable at quality for all. As a child, while studying in school, I remember a shloka which I had recited, " Vidya dadati vinayam, vinayadhyati patratam, patratwadhanam apnoti, dhanat dharmam, tatah sukham. Meaning, Education fills you with morals, morals make you capable, capability brings success and wealth, wealth helps you follow your beliefs, and that is what leads to a happy life. India, is a land of strong moral values and its teachings are so deeply ingrained for creating a responsible individual, we understand what it means to have quality education that instills moral values in you while developing technical and social aptitude. Our patron organisation, Youth India Foundation also emphasises immensely on permanent and consistent education delivery for every child from an underprivileged sector for making them capable of sustaining themselves. While in India, the educational infrastructure arena still has a huge gap to fill, E-Schools brings an array of tools starting from technical infrastructure like a wholesome executive application which is our E-School software to transformational infrastructure like Smart  Classes. We envisage making quality education in India accessible to all. We also specialize in Gov-Tech Software wherein we are working with multiple state governments on digitization, transformation, and policy-making arena. Our team of former decorated bureaucrats, advisors, educationists, techies, and the youth make E-School the right choice for your institution, group of institutions, or the state. Join us in our journey of transforming India's Educational Landscape, just one click at a time!
                                </p>

                                {/* <a href="#"><img src="images/team/th.jpg" data-src="images/icon/icon_05.svg" alt="" class="lazy-img" /></a> */}
                            </div>

                        </div>

                    </div>
                </div>
            </div>


            <div class="fancy-feature-seven pt-200 lg-pt-100">
                <div class="container">
                    <div class="column align-items-center">
                        <div class="col-xl-6 col-lg-5 wow fadeInLeft">
                            <div class="title-style-four text-center text-lg-start">
                                <h2 class="main-title fw-500 tx-dark m0">Sundargarh <span>Project.</span></h2>
                            </div>
                        </div>
                        <br />
                        <div class="col-lg-6  wow fadeInRight">
                            <p class="text-lg text-center text-lg-start md-pt-30 m0">
                                In association with Mo School and the Sundargarh administration, Youth India launched the project in 50 high schools as part of the state government's 5T initiative. With their expertise and dedication, Youth India and its collaborators successfully introduced various infrastructural facilities in these institutes. Among the initiatives, the introduction of online classes has garnered immense popularity among teachers, showcasing Youth India's instrumental role in implementing innovative educational methods.
                            </p>
                        </div>
                        <div style={{ paddingTop: "100px", justifyContent: "center" }}>
                            <Carousel>
                                <Carousel.Item interval={1500}>
                                    <img
                                        className="d-block ms-auto me-auto"
                                        src="../images/carosel/4.jpg"
                                        alt="Image One"
                                        style={{ borderRadius: "25px", maxHeight: "600px", width: "auto" }}
                                    />
                                    {/* <Carousel.Caption>
                                            <h3>Label for first slide</h3>
                                            <p>Sample Text for Image One</p>
                                        </Carousel.Caption> */}
                                </Carousel.Item>
                                <Carousel.Item interval={1500}>
                                    <img
                                        className="d-block ms-auto me-auto"
                                        src="../images/carosel/5.jpg"
                                        alt="Image Two"
                                        style={{ borderRadius: "25px", maxHeight: "600px", width: "auto" }}
                                    />
                                </Carousel.Item>
                                <Carousel.Item interval={1500}>
                                    <img
                                        className="d-block ms-auto me-auto"
                                        src="../images/carosel/6.jpg"
                                        alt="Image Two"
                                        style={{ borderRadius: "25px", maxHeight: "600px", width: "auto" }}
                                    />
                                </Carousel.Item>
                                <Carousel.Item interval={1500}>
                                    <img
                                        className="d-block ms-auto me-auto"
                                        src="../images/carosel/10.jpg"
                                        alt="Image Two"
                                        style={{ borderRadius: "25px", maxHeight: "600px", width: "auto" }}
                                    />
                                </Carousel.Item>
                                <Carousel.Item interval={1500}>
                                    <img
                                        className="d-block ms-auto me-auto"
                                        src="../images/carosel/14.jpg"
                                        alt="Image Two"
                                        style={{ borderRadius: "25px", maxHeight: "600px", width: "auto" }}
                                    />
                                </Carousel.Item>
                                <Carousel.Item interval={1500}>
                                    <img
                                        className="d-block ms-auto me-auto"
                                        src="../images/carosel/15.jpg"
                                        alt="Image Two"
                                        style={{ borderRadius: "25px", maxHeight: "600px", width: "auto" }}
                                    />
                                </Carousel.Item>
                                <Carousel.Item interval={1500}>
                                    <img
                                        className="d-block ms-auto me-auto"
                                        src="../images/carosel/16.jpg"
                                        alt="Image Two"
                                        style={{ borderRadius: "25px", maxHeight: "600px", width: "auto" }}
                                    />
                                </Carousel.Item>
                                <Carousel.Item interval={1500}>
                                    <img
                                        className="d-block ms-auto me-auto"
                                        src="../images/carosel/17.jpg"
                                        alt="Image Two"
                                        style={{ borderRadius: "25px", maxHeight: "600px", width: "auto" }}
                                    />
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                </div>
                {/* <div class="container pt-50 lg-pt-10">
                        <div class="row gx-xxl-5">
                            <div class="col-lg-4 col-md-6 wow fadeInUp">
                                <div class="card-style-four text-center position-relative mt-40 xs-mt-20">
                                    <div class="icon rounded-circle m-auto d-flex align-items-center justify-content-center" ><img src="images/icon/icon_24.svg" alt="" class="lazy-img" /></div>
                                    <h4 class="fw-500 mt-35 mb-25"><a href="#" class="tran3s tx-dark">Administrative Management System</a></h4>
                                    <p class="mb-30">Manage Schools Data and flow in one roof. Create, Update, Delete users with ease.</p>
                                    <a href="#"><img src="images/icon/icon_05.svg" alt="" class="lazy-img" /></a>
                                </div>
                                
                            </div>
                            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
                                <div class="card-style-four text-center position-relative mt-40 xs-mt-20">
                                    <div class="icon rounded-circle m-auto d-flex align-items-center justify-content-center" ><img src="images/icon/icon_25.svg" alt="" class="lazy-img" /></div>
                                    <h4 class="fw-500 mt-35 mb-25"><a href="#" class="tran3s tx-dark">Communication Management System</a></h4>
                                    <p class="mb-30">Students, Teachers , Admin can communicate in one chat system for interaction.</p>
                                    <a href="#"><img src="images/icon/icon_05.svg" alt="" class="lazy-img" /></a>
                                </div>
                                
                            </div>
                            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.4s">
                                <div class="card-style-four text-center position-relative mt-40 xs-mt-20">
                                    <div class="icon rounded-circle m-auto d-flex align-items-center justify-content-center" ><img src="images/icon/icon_26.svg" alt="" class="lazy-img" /></div>
                                    <h4 class="fw-500 mt-35 mb-25"><a href="#" class="tran3s tx-dark">Learning Management System</a></h4>
                                    <p class="mb-30">Take Advantages of our learning management with content creation , progress chart.</p>
                                    <a href="#"><img src="images/icon/icon_05.svg" alt="" class="lazy-img" /></a>
                                </div>
                                
                            </div>
                        </div>
                        <div class="row gx-xxl-5">
                            <div class="col-lg-4 col-md-6 wow fadeInUp">
                                <div class="card-style-four text-center position-relative mt-40 xs-mt-20">
                                    <div class="icon rounded-circle m-auto d-flex align-items-center justify-content-center" ><img src="images/icon/icon_24.svg" alt="" class="lazy-img" /></div>
                                    <h4 class="fw-500 mt-35 mb-25"><a href="#" class="tran3s tx-dark">Assessment Management System</a></h4>
                                    <p class="mb-30">Assesment can be created with ease, Random question generaor and auto Evaluator.</p>
                                    <a href="#"><img src="images/icon/icon_05.svg" alt="" class="lazy-img" /></a>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.4s">
                                <div class="card-style-four text-center position-relative mt-40 xs-mt-20">
                                    <div class="icon rounded-circle m-auto d-flex align-items-center justify-content-center" ><img src="images/icon/icon_26.svg" alt="" class="lazy-img" /></div>
                                    <h4 class="fw-500 mt-35 mb-25"><a href="#" class="tran3s tx-dark">Financial Management System</a></h4>
                                    <p class="mb-30">Hassle free management of payroll, fees, expenses, and reimbursement.</p>
                                    <a href="#"><img src="images/icon/icon_05.svg" alt="" class="lazy-img" /></a>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
                                <div class="card-style-four text-center position-relative mt-40 xs-mt-20">
                                    <div class="icon rounded-circle m-auto d-flex align-items-center justify-content-center"  ><img src="images/icon/icon_25.svg" alt="" class="lazy-img" /></div>
                                    <h4 class="fw-500 mt-35 mb-25"><a href="#" class="tran3s tx-dark">Reports & Analytics</a></h4>
                                    <p class="mb-30">Get all reports of attendance , learning management and assessment management.</p>
                                    <a href="#"><img src="images/icon/icon_05.svg" alt="" class="lazy-img" /></a>
                                </div>
                            </div>
                        </div>
                    </div> */}
            </div>

            <div class="partner-section-two position-relative mt-225 mb-250 md-mt-120 md-mb-120">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-xl-5 col-lg-6 wow fadeInLeft" style={{ visibility: "visible", animationName: "fadeInLeft" }}>
                            <div class="title-style-four">
                                {/* <!-- <div class="sc-title-two" style="color:#6A45FF;">Our Partners</div> --> */}
                                <h2 class="main-title fw-500 tx-dark m0" style={{ fontFamily: "gordita" }}>Our Clients & <span>Partners.</span></h2>
                            </div>
                            <p class="fs-20 pt-30 pe-xxl-5">Join our network of esteemed clients and partners shaping the future of education.</p>
                        </div>
                    </div>
                </div>

                <div class="logo-wrapper d-flex flex-wrap">
                    
                    {
                        isMobileView === false ?
                        <Marquee gradient="true" speed={100}>
                            <div class=" d-flex" style={{justifyContent:"space-between"}} >
                                <img src="images/partners/auro.png" alt="" class="lazy-img" style={{marginRight:"35px", marginLeft:"35px"}} />
                                <img src="images/partners/districtAdmin.jpg" alt="" class="lazy-img" style={{marginRight:"35px", marginLeft:"35px"}}/>
                                <img src="images/partners/Moschool.jpg" alt="" class="lazy-img" style={{marginRight:"35px", marginLeft:"35px"}}/>
                                <img src="images/partners/vedanta.jpg" alt="" class="lazy-img" style={{ marginLeft:"35px", marginRight:"35px"}}/>
                            </div>
                        </Marquee>
                        :
                        <Marquee speed={100}>
                            <div class=" d-flex" style={{justifyContent:"space-between"}} >
                                <img src="images/partners/auro.png" alt="" class="lazy-img" style={{marginRight:"35px", marginLeft:"35px"}} />
                                <img src="images/partners/districtAdmin.jpg" alt="" class="lazy-img" style={{marginRight:"35px", marginLeft:"35px"}}/>
                                <img src="images/partners/Moschool.jpg" alt="" class="lazy-img" style={{marginRight:"35px", marginLeft:"35px"}}/>
                                <img src="images/partners/vedanta.jpg" alt="" class="lazy-img" style={{ marginLeft:"35px", marginRight:"35px"}}/>
                            </div>
                        </Marquee>
                    }
                    
                    {/* <div class="logo d-flex align-items-center justify-content-center"><img src="images/partners/auro.png" alt="" width="90" class="lazy-img" /></div>
                        <div class="logo d-flex align-items-center justify-content-center"><img src="images/partners/districtAdmin.jpg" alt="" width="70" class="lazy-img" /></div>
                        <div class="logo d-flex align-items-center justify-content-center"><img src="images/partners/Moschool.jpg" alt="" width="90" class="lazy-img" /></div>
                        <div class="logo d-flex align-items-center justify-content-center"><img src="images/partners/vedanta.jpg" alt="" width="80" class="lazy-img" /></div> */}
                    {/* <div class="logo d-flex align-items-center justify-content-center"><img src="images/logo/Plogo-11.png" alt="" class="lazy-img" /></div>
                        <div class="logo d-flex align-items-center justify-content-center"><img src="images/logo/Plogo-12.png" alt="" class="lazy-img" /></div>
                        <div class="logo d-flex align-items-center justify-content-center"><img src="images/logo/Plogo-13.png" alt="" class="lazy-img" /></div> */}
                </div>
            </div>
            <Testimonials />
            <div class="fancy-short-banner-sixteen mt-130 lg-mt-100 pb-50 wow fadeInUp">
                <div class="container">
                    <div class="bg-wrapper pt-65 pb-65 lg-pt-40 lg-pb-40">
                        <div class="row">
                            <div class="col-xl-10 col-md-11 m-auto">
                                <div class="row align-items-center">
                                    <div class="col-lg-6">
                                        <div class="text-wrapper text-center text-lg-start md-pb-30">
                                            <div class="sc-title fs-18 pb-10" style={{ color: "#fff", fontWeight: 500 }}>Have Any Project?</div>
                                            <h2 class="main-title fw-500 text-white m0">Don’t hesitate to send us a message.</h2>
                                        </div>
                                    </div>
                                    <div class="col-lg-5 ms-auto text-center text-lg-end">
                                        <a href="/contactus" class="btn-twentyOne fw-500 tran3s">Get Started Today!</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )


}

export default AboutUs;